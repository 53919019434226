import { Fragment, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useWorkspaceContext } from 'src/context/workspace.context';
import { IProject } from 'src/types';
import CreateTaskModal from './CreateTaskModal';
import TasksArena from './TasksArena';

interface TaskTabContainerProps {
  projectId: string;
  workspaceSlug: string;
  projectData: IProject;
  heightHeadingContainerRef: number;
}

const TaskTabContainer = () => {
  const { projectId, workspaceSlug, projectData, heightHeadingContainerRef } =
    useOutletContext<TaskTabContainerProps>();

  const { workspaceWorkstreams } = useWorkspaceContext();

  const [createTaskModal, setCreateTaskModal] = useState(false);

  return (
    <Fragment>
      <div className="h-[100%] w-[100%] relative">
        {projectId && workspaceSlug && (
          <TasksArena
            projectData={projectData}
            heightHeadingContainerRef={heightHeadingContainerRef}
            handleCreateTask={() => setCreateTaskModal(true)}
            collaborators={projectData?.collaborators}
            workStreams={workspaceWorkstreams}
          />
        )}
      </div>

      {workspaceSlug && (
        <CreateTaskModal
          show={createTaskModal}
          handleClose={() => setCreateTaskModal(false)}
          workspaceSlug={workspaceSlug}
          collaborators={projectData?.collaborators}
          workStreams={projectData?.workspace.topics}
        />
      )}
    </Fragment>
  );
};

export default TaskTabContainer;
