import { useLocalStorage } from '@uidotdev/usehooks';
// import { AnimatePresence, motion } from 'framer-motion';
import parse from 'html-react-parser';
import { Fragment, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
// import { ChevronDown } from 'src/assets/icons';
import ToastMessage from 'src/components/ToastMessage';
import Avatar from 'src/components/ui/Avatar/Avatar';
// import IconButton from 'src/components/ui/Button/IconButton';
import Timestamp from 'src/components/ui/TimeStamp';
import { useCommentEdit } from 'src/hooks/project/post/useCommentEditAction';
import { IComment, IUser } from 'src/types';
import PostMenu from '../../posts/PostMenu';
import CommentInputField from '../CommentInputField';
import DeletePostCommentModal from './DeletePostCommentModal';

interface IPostCommentBodyProps {
  comment: IComment;
  collaborators: IUser[];
  showMenu?: boolean;
  setShowMenu?: (value: boolean) => void;
}

function PostCommentBody({
  comment,
  collaborators,
  showMenu,
  setShowMenu,
}: IPostCommentBodyProps) {
  const [userId] = useLocalStorage('userId', undefined);
  const [searchParams] = useSearchParams();

  const isCommentOwner = userId === comment.author.id;
  const commentId = searchParams.get('commentId');
  const isFocusedComment = commentId === comment.id;

  const commentRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const [deleteComment, setDeleteComment] = useState<IComment | null>(null);

  const {
    isEditing,
    startEditing,
    cancelEditing,
    control,
    handleSubmit,
    handleEditComment,
    handleEditorChange,
    isUpdating,
    editedComment,
    editorConfig,
    initializeEditor,
    handleMentionButtonClick,
    handleEmojiClick,
  } = useCommentEdit({
    comment,
    collaborators,
    onEditComplete: () => {},
  });

  const handleCopy = () => {
    const origin = window.location.origin;
    const post = comment.post;
    const project = post.project;
    const workspace = project.workspace;

    const link = `${origin}/workspace/${workspace.slug}/project/${project.id}/posts/${post.id}?commentId=${comment.id}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast((t) => (
          <ToastMessage
            id={t.id}
            title={'Link to comment is copied to clipboard'}
          />
        ));
      })
      .catch((err) => {
        toast((t) => (
          <ToastMessage id={t.id} title={'Error copying text to clipboard'} />
        ));
      });
  };

  useEffect(() => {
    if (isFocusedComment && commentRef.current instanceof HTMLElement) {
      const element = commentRef.current;

      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });

      element.classList.add('bg-[var(--backgrounds-hover-clicked)]');
      setTimeout(() => {
        element.classList.remove('bg-[var(--backgrounds-hover-clicked)]');
      }, 2000);

      return () => {
        element.classList.remove('bg-[var(--backgrounds-hover-clicked)]');
      };
    }
  }, [isFocusedComment]);

  return (
    <Fragment>
      <div
        ref={commentRef}
        className="w-full grid grid-cols-[32px_1fr] gap-x-2 duration-1000"
      >
        <div className="h-max w-full pt-1">
          <Avatar
            alt={comment.author.detail?.fullName ?? comment.author?.email}
            src={comment.author.detail.avatar?.url}
            size={32}
            name={comment.author.detail?.fullName}
          />
        </div>

        <div className="flex-1 flex flex-col items-start">
          <div className="w-full flex items-center justify-between mb-[2px]">
            <div className="w-max flex items-center gap-x-2">
              <div className="text-body-small text-[var(--text-default)] font-medium">
                {comment.author.detail?.fullName}
              </div>

              <Timestamp
                createdAt={comment.createdAt}
                className="text-body-micro text-[var(--text-neutral)] regular"
              />
            </div>

            <PostMenu
              showMenu={showMenu}
              setShowMenu={setShowMenu}
              canEdit={isCommentOwner}
              canDelete={isCommentOwner}
              handleCopy={handleCopy}
              handleEdit={startEditing}
              handleDelete={() => setDeleteComment(comment)}
              type={'comment'}
            />
          </div>

          {isEditing ? (
            <CommentInputField
              isEditing={isEditing}
              name="editedComment"
              control={control}
              onReady={initializeEditor}
              handleEditorChange={handleEditorChange}
              editorConfig={editorConfig}
              loadingBtn={isUpdating}
              disabledBtn={isUpdating || editedComment === comment.comment}
              onMentionClick={handleMentionButtonClick}
              onEmojiClick={handleEmojiClick}
              onClick={handleSubmit(handleEditComment)}
              btnLabel={'Update'}
              cancelEditing={cancelEditing}
            />
          ) : (
            <div className="w-full relative">
              <div
                ref={contentRef}
                className={`ck ck-content text-body-small regular text-[var(--text-default)]`}
              >
                {parse(comment.comment)}
              </div>
            </div>
          )}
        </div>
      </div>

      <DeletePostCommentModal
        comment={deleteComment}
        show={Boolean(deleteComment)}
        handleClose={() => setDeleteComment(null)}
      />
    </Fragment>
  );
}

export default PostCommentBody;
