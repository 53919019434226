import classNames from 'classnames';
import { motion } from 'framer-motion';
import { Fragment, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Check,
  IconChevronRight,
  IconMoonStar,
  IconProjects,
  IconSun,
  MegaPhone,
  Users,
} from 'src/assets/icons';
import SectionWrap from 'src/components/common/SectionWrap';
import PostModal from 'src/components/projects/posts/PostModal';
import ProjectModal from 'src/components/projects/ProjectModal';
import ToastMessage from 'src/components/ToastMessage';
import Button from 'src/components/ui/Button/Button';
import IconButton from 'src/components/ui/Button/IconButton';
import StatusTag from 'src/components/ui/tags/StatusTags';
import { useUser } from 'src/context/user.context';
import { useWorkspaceContext } from 'src/context/workspace.context';
import { tv } from 'tailwind-variants';

export default function GetStartedPage() {
  const navigate = useNavigate();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();

  const { user } = useUser();
  const { currentWorkspace } = useWorkspaceContext();
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [showPostModal, setShowPostModal] = useState(false);

  const setupItems = [
    {
      id: 1,
      show: true,
      icon: <IconProjects className="w-6 h-6" />,
      title: 'Create a project',
      description: 'What’s your team currently working on?',
      actionLabel: 'Create Project',
      action: () => {
        setShowProjectModal(true);
      },
      done: user?.actionStatus?.hasCreatedProject,
    },
    {
      id: 2,
      show: true,
      icon: <MegaPhone className="w-6 h-6" />,
      title: 'Make a post',
      description: 'For project related conversations or updates',
      actionLabel: 'Make a post',
      action: () => {
        if (!user?.actionStatus?.hasCreatedProject) {
          toast(
            (t) => (
              <ToastMessage
                id={t.id}
                title="You need to create a project first"
                buttonText="Create"
                buttonAction={() => {
                  setShowProjectModal(true);
                  toast.dismiss(t.id);
                }}
              />
            ),
            { duration: 5000 }
          );
          return;
        }
        setShowPostModal(true);
      },
      done: user?.actionStatus?.hasCreatedPost,
    },
    {
      id: 3,
      show: true,
      icon: <Users className="w-6 h-6" />,
      title: 'Invite teammates',
      description: 'Get your team aboard so everyone can collaborate',
      actionLabel: 'Invite',
      action: () => {
        navigate(`/invite-to-workspace?workspace=${workspaceSlug}`);
      },
      done: user?.actionStatus?.hasInvitedTeamMembers,
    },
    {
      id: 4,
      title: 'Add verified domains',
      show: currentWorkspace?.isOwner,
      action: () => {
        navigate(`/workspace/${workspaceSlug}/settings/security`);
      },
      done: user?.actionStatus?.hasVerifiedDomain,
    },
  ];

  const getCurrentGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) {
      return {
        text: 'Good Morning',
        icon: <IconSun className="w-5 h-5 fill-neutral" />,
      };
    } else if (hour < 18) {
      return {
        text: 'Good Afternoon',
        icon: <IconSun className="w-5 h-5 fill-neutral" />,
      };
    } else {
      return {
        text: 'Good Evening',
        icon: <IconMoonStar className="w-5 h-5 fill-neutral" />,
      };
    }
  };

  const { text: currentGreeting, icon: currentGreetingIcon } =
    getCurrentGreeting();

  return (
    <Fragment>
      <SectionWrap className="w-full md:max-w-[70%] overflow-auto p-6">
        <div className="mb-8 w-full flex flex-col items-center gap-y-[2px]">
          <h4 className="text-heading-4 medium text-[var(--text-default)] flex items-center gap-x-1">
            <span>
              {currentGreeting}, {user?.detail?.fullName || 'User'}
            </span>
            <span>{currentGreetingIcon}</span>
          </h4>
          <p className="text-body-small regular text-[var(--text-neutral)]">
            Welcome to Overlap, here’s how to get started.
          </p>
        </div>
        <div className="flex flex-col items-center gap-y-6">
          <section
            className={`${sectionContainerStyles({
              fill: 'canvas',
            })} p-4 flex flex-col gap-y-2`}
          >
            {setupItems.map((item) => (
              <button
                key={item.id}
                className={classNames(
                  '!w-full py-1.5 px-2 rounded-lg min-h-8',
                  'hover:bg-[var(--backgrounds-hover-clicked)]',
                  !item.show && 'hidden'
                )}
                style={{
                  pointerEvents: item.done ? 'none' : 'auto',
                }}
                onClick={item.action}
              >
                <div className="flex items-center justify-between !w-full">
                  <div className="flex items-center justify-start w-max gap-x-2">
                    {!item.done && (
                      <motion.div
                        initial={{ scale: 0 }}
                        animate={{
                          scale: !item.done ? 1 : 0,
                        }}
                        transition={{ duration: 0.3 }}
                        className="border-2 border-dashed border-[var(--border-on-bg)] rounded-full w-5 h-5 flex items-center justify-center text-body-small regular text-[var(--text-neutral)]"
                      >
                        {item.id}
                      </motion.div>
                    )}
                    {item.done && (
                      <motion.div
                        initial={{ scale: 0 }}
                        animate={{
                          scale: item.done ? 1 : 0,
                        }}
                        transition={{ duration: 0.3 }}
                        className="w-fit"
                      >
                        <Check className="h-5 w-5 success" />
                      </motion.div>
                    )}
                    <p className="text-body-small regular text-[var(--text-default)] relative flex items-center">
                      {item.title}
                      {item.done && (
                        <motion.span
                          className="absolute bg-[currentColor] h-[1px]"
                          initial={{ width: 0 }}
                          animate={{ width: item.done ? '100%' : 0 }}
                          transition={{ duration: 0.3 }}
                        />
                      )}
                    </p>
                  </div>

                  {!item.done && (
                    <IconButton
                      icon={<IconChevronRight className="w-3 h-3" />}
                      size={'micro'}
                      rounded={'micro'}
                      type={'ghost'}
                      onClick={item.action}
                    />
                  )}

                  {item.done && (
                    <StatusTag
                      value="Completed"
                      color="success"
                      size="medium"
                      type="filled"
                    />
                  )}
                </div>
              </button>
            ))}
          </section>
          <section className="w-full grid grid-cols-[1fr_1fr] gap-[22px]">
            {setupItems.slice(0, 2).map((item) => (
              <div
                key={item.id}
                className={`${sectionContainerStyles({
                  fill: 'default',
                })} p-6 flex flex-col gap-y-4 items-start min-h-[186px]`}
              >
                {item.icon}
                <div className="flex flex-col items-start w-full gap-y-2">
                  <h5 className="text-body-small medium text-[var(--text-default)]">
                    {item.title}
                  </h5>
                  <p className="text-body-small regular text-[var(--text-neutral)]">
                    {item.description}
                  </p>
                </div>
                <Button
                  type="button"
                  value={item.actionLabel as string}
                  size="medium"
                  btnType="neutral"
                  onClick={item.action}
                  className={'w-fit'}
                />
              </div>
            ))}
          </section>

          {setupItems.slice(2, 3).map((item) => (
            <section
              key={item.id}
              className={`${sectionContainerStyles({
                fill: 'default',
              })} grid grid-cols-[1fr_340px] min-h-[207px] !overflow-hidden`}
            >
              <div className="w-full pl-6 py-6 flex flex-col items-start gap-y-4">
                {item.icon}
                <div className="w-full flex flex-col items-start gap-y-2">
                  <h5 className="text-body-small medium text-[var(--text-default)]">
                    {item.title}
                  </h5>
                  <p className="text-body-small regular text-[var(--text-neutral)]">
                    {item.description}
                  </p>
                </div>
                <Button
                  value={item.actionLabel as string}
                  size="medium"
                  btnType="neutral"
                  onClick={item.action}
                  className={'w-fit'}
                />
              </div>
              <div
                className="w-full relative"
                style={{
                  background: 'var(--get-started-section)',
                }}
              >
                <div
                  className="absolute w-[130px] h-full left-0 top-0"
                  style={{
                    background: 'var(--get-started-blend-bg)',
                  }}
                />
                <div className={`right-[27.4px] top-[6px] ${userIconStyles()}`}>
                  <img
                    src={'/images/get-started/notion-u.png'}
                    alt="Notion User"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div
                  className={`top-[33px] right-[97.5px] ${userIconStyles()}`}
                >
                  <img
                    src={'/images/get-started/jira-u.png'}
                    alt="Jira User"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className={`left-[86px] top-[39px] ${userIconStyles()}`}>
                  <img
                    src={'/images/get-started/linear-u.png'}
                    alt="Linear User"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div
                  className={`right-[17.2px] bottom-[6px] ${userIconStyles()}`}
                >
                  <img
                    src={'/images/get-started/figma-u.png'}
                    alt="Figma User"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div
                  className={`right-[128px] bottom-[33px] ${userIconStyles()}`}
                >
                  <img
                    src={'/images/get-started/photoshop-u.png'}
                    alt="Photoshop User"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div
                  className={`left-[65.8px] bottom-[16px] ${userIconStyles()}`}
                >
                  <img
                    src={'/images/get-started/next-u.png'}
                    alt="Next User"
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
            </section>
          ))}
        </div>
      </SectionWrap>

      <ProjectModal
        show={showProjectModal}
        handleClose={() => setShowProjectModal(false)}
      />

      <PostModal
        show={showPostModal}
        handleClose={() => setShowPostModal(false)}
      />
    </Fragment>
  );
}

const sectionContainerStyles = tv({
  base: 'border border-[var(--border-on-bg)] rounded-3xl w-full ',
  variants: {
    fill: {
      canvas: 'bg-[var(--backgrounds-on-canvas)]',
      default: 'bg-[var(--backgrounds-default)]',
    },
  },
});

const userIconStyles = tv({
  base: 'absolute w-[48.75px] h-[53px] hover:scale-110 transition-transform duration-300 ease-in-out',
});
