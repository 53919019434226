import { useLocalStorage } from '@uidotdev/usehooks';
import clsx from 'clsx';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Lock, Plus, Triangle } from 'src/assets/icons';
import { useWorkspaceContext } from 'src/context/workspace.context';
import { colorStyles } from 'src/lib/colorPreset';
import { getColorTag, userExistAsCollaborator } from 'src/lib/helpers';
import { IProject } from 'src/types';
import IconButton from '../ui/Button/IconButton';
import SideNavMenuBtn from './SideNavMenu';

interface NavigationHeaderProps {
  baseLink: string;
  label: string | number;
  openInbox: boolean;
  secNavFunc?: () => void;
}

const NavigationHeader: React.FC<NavigationHeaderProps> = ({
  label,
  secNavFunc,
  openInbox,
  baseLink,
}) => {
  const [userId] = useLocalStorage('userId', undefined);
  const { workspaceProjects } = useWorkspaceContext();
  const [toggleNavChild, setToggleNavChild] = useState(true);

  const sortedProjects = [...(workspaceProjects || [])].sort((a, b) => {
    const aCollaborates = userExistAsCollaborator(userId, a);
    const bCollaborates = userExistAsCollaborator(userId, b);

    if (aCollaborates && !bCollaborates) return -1;
    if (!aCollaborates && bCollaborates) return 1;

    return 0;
  });

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between w-full pr-2.5">
        <button
          className="side__menu__btn px-3 py-0.5 h-6 gap-2 truncate !justify-start w-fit"
          onClick={() => setToggleNavChild(!toggleNavChild)}
        >
          <div className="text-body-micro bold truncate capitalize text-[var(--text-disabled)]">
            {label}
          </div>
          <div
            className={clsx(
              'dropdown_icon inline-block transition-transform duration-300',
              {
                'rotate-0': toggleNavChild,
                '-rotate-90': !toggleNavChild,
              }
            )}
          >
            <Triangle />
          </div>
        </button>
        <div>
          <IconButton
            open={openInbox}
            type="ghost"
            size="small"
            icon={<Plus />}
            onClick={secNavFunc}
          />
        </div>
      </div>
      <div className="flex flex-col gap-1 max-h-[450px] overflow-y-auto">
        {toggleNavChild &&
          sortedProjects?.map((project: IProject) => {
            const userCollaborates = userExistAsCollaborator(userId, project);
            return (
              <Link key={project.id} to={`${baseLink}/${project.id}`}>
                <SideNavMenuBtn
                  avatarColor={colorStyles[getColorTag(project.name)]}
                  sideAvatar={project.name}
                  type="home"
                  label={project.name}
                  link={`${baseLink}/${project.id}`}
                  showBadge={false}
                  customClassName={!userCollaborates ? 'opacity-50' : ''}
                  indicator={
                    !project.isPublic && (
                      <IconButton
                        type="ghost"
                        size="micro"
                        icon={<Lock className="!h-3 !w-3" />}
                        onClick={() => {}}
                      />
                    )
                  }
                />
              </Link>
            );
          })}
      </div>
    </div>
  );
};

export default NavigationHeader;
