import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
// Import types and icons
import { Popover, Transition } from '@headlessui/react';
import { IUser, IUserWorkspace } from 'src/lib/types';
// Import hooks and helpers
import { useWorkspace } from 'src/api/workspace';
import { fuzzySearchRegex, transformToTitleCase } from 'src/lib/helpers';
// import Avatar from "src/components/Avatar";
import { useDebounce } from '@uidotdev/usehooks';
import classNames from 'classnames';
import usePrevious from 'src/hooks/usePrevious';
// Import Avatar and other UI components
import { Check, Plus } from 'src/assets/icons';
import Avatar from '../ui/Avatar/Avatar';
import HorizontalDivider from '../ui/HorizontalDivider';

// Define the component props interface
interface ProjectLeadSelectProps {
  onChange: (value: string) => void;
  projectId?: string;
  userId?: string | null;
  value: string;
  workspaceSlug: string;
  readOnly?: boolean;
}

// Define the main component
const ProjectLeadSelect: React.FC<ProjectLeadSelectProps> = (props) => {
  const {
    projectId,
    value,
    onChange,
    workspaceSlug,
    userId,
    readOnly = true,
  } = props;
  // State for the search term
  const [searchTerm, setSearchTerm] = React.useState('');
  // Debounce the search term to improve performance
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  // Create a regex for fuzzy search
  const regex = fuzzySearchRegex(debouncedSearchTerm);
  // Handle search term change
  const handleSearchTermChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };
  // Filter function for search term
  const searchTermFilter = (user: IUser) => {
    if (debouncedSearchTerm.length) {
      return regex.test(user.detail?.fullName);
    }
    return true;
  };

  // Fetch workspace data
  const { loading, data } = useWorkspace({
    variables: {
      where: { slug: workspaceSlug },
    },
  });

  // Extract project leads from workspace members and filter based on search term
  const projectLeads =
    data?.workspace?.members
      .map((member: IUserWorkspace) => member?.user)
      .filter(searchTermFilter) ?? [];

  // Get the previous state of project leads
  const prevprojectLeads = usePrevious(projectLeads);
  // State for selected options
  const [selectedOption, setSelectedOption] = useState<IUser | null>(null);

  // Effect to update selected option when project leads change
  useEffect(() => {
    if (
      prevprojectLeads?.length !== projectLeads.length &&
      projectLeads.length
    ) {
      const initialSelection = projectLeads.find(
        (each: IUser) =>
          value?.includes(each.id) || (userId && each.id === userId)
      );
      setSelectedOption(initialSelection || null);
    }
  }, [prevprojectLeads, projectLeads]);

  // Handle checkbox change for selecting options
  const handleCheckboxChange = (option: IUser) => {
    const newSelection = selectedOption?.id === option.id ? null : option;
    setSelectedOption(newSelection);
    onChange(newSelection ? newSelection.id : '');
  };

  // Show loading state
  if (loading) return null;

  // Button to show selected options
  const button = selectedOption ? (
    <ul className="flex items-center -space-x-2 rounded-lg w-fit hover:bg-[var(--backgrounds-default)] p-1 px-[6px]">
      <li key={selectedOption.id} className="flex gap-1.5 items-center">
        <Avatar
          name={selectedOption.detail?.fullName ?? selectedOption.email}
          src={selectedOption.detail?.avatar?.url}
          alt={selectedOption.detail?.fullName}
          size={20}
        />
        <p className="text-body-micro medium text-[var(--text-default)] max-w-[150px] truncate">
          {selectedOption.detail?.fullName ?? selectedOption.email}
        </p>
      </li>
    </ul>
  ) : (
    <div className="button ghost small justify-start w-fit transition !pl-1.5">
      <Plus />
      <span className="text-[var(--text-on-bg)]  text-body-micro font-medium">
        Select project lead
      </span>
    </div>
  );

  // Check if there are no search results
  const noSearchResults =
    projectLeads.length === 0 && debouncedSearchTerm.length > 0;

  // Main render of the component
  return (
    <Popover className={`relative`}>
      {({ open }) => (
        <>
          <Popover.Button className={`${open ? '' : ''} justify-start`}>
            {button}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-1 md:left-0 z-10 mt-2 max-w-[250px] w-full space-y-4">
              <div className="drop-group p-1  w-full max-h-[470px] overflow-y-auto ">
                <div className="sticky top-0 z-10">
                  {readOnly || (
                    <div className="relative bg-[var(--backgrounds-dropdowns)] rounded-lg">
                      {/* Search Term Input */}
                      <input
                        autoFocus
                        value={searchTerm}
                        onChange={handleSearchTermChange}
                        id="collaborator-search"
                        type=""
                        className="input-clear h-[30px] !px-2 w-full text-[var(--text-default)] text-body-small regular placeholder:text-[var(--text-neutral)]"
                        placeholder="Select project lead"
                      />
                    </div>
                  )}
                </div>

                {readOnly || <HorizontalDivider />}
                <div className="">
                  {noSearchResults && (
                    <p className="text-center p-4 text-body-micro regular text-[var(--text-neutral)] whitespace-break-spaces">
                      No search found for “
                      <span className="font-semibold">
                        {debouncedSearchTerm}
                      </span>
                      ” in your workspace.
                    </p>
                  )}
                  <ul>
                    {!readOnly &&
                      projectLeads.map((user: IUser) => (
                        <li key={user.id} className="px-2">
                          <label
                            className={classNames(
                              'flex items-center gap-2 h-11',
                              { 'cursor-pointer': !readOnly }
                            )}
                          >
                            <Avatar
                              name={user.detail?.fullName ?? user.email}
                              src={user.detail?.avatar?.url}
                              alt={user.detail?.fullName}
                              size={28}
                            />
                            <div className="flex-1">
                              <div className="text-body-small medium text-[var(--text-default)] max-w-[150px] truncate">
                                {user.detail?.fullName ?? user.email}
                              </div>
                              <div className="text-body-micro regular text-[var(--text-neutral)]">
                                {transformToTitleCase(user.detail?.position)}
                              </div>
                            </div>
                            {readOnly || (
                              <div className="">
                                {selectedOption?.id === user.id && (
                                  <div>
                                    <Check />
                                  </div>
                                )}
                                <input
                                  type="checkbox"
                                  className="sr-only input-clear border border-[var(--border-default)] !rounded bg-[var(--backgrounds-on-canvas)] checked:bg-[rgba(129, 120, 239, 1)]"
                                  value={user as any}
                                  checked={selectedOption?.id === user.id}
                                  onChange={() => handleCheckboxChange(user)}
                                />
                              </div>
                            )}
                          </label>
                        </li>
                      ))}
                    {readOnly && selectedOption && (
                      <li
                        key={selectedOption.id}
                        className="flex gap-1.5 items-center px-2"
                      >
                        <Avatar
                          name={
                            selectedOption.detail?.fullName ??
                            selectedOption.email
                          }
                          src={selectedOption.detail?.avatar?.url}
                          alt={selectedOption.detail?.fullName}
                          size={28}
                        />
                        <div className="flex flex-col">
                          <p className="text-body-small medium text-[var(--text-default)]">
                            {selectedOption.detail?.fullName ??
                              selectedOption.email}
                          </p>
                          <p className="text-body-micro regular text-[var(--text-neutral)]">
                            {transformToTitleCase(
                              selectedOption.detail?.position
                            )}
                          </p>
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default ProjectLeadSelect;
