import { CKEditor } from '@ckeditor/ckeditor5-react';
import BalloonEditor from 'ckeditor5-custom-build';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { ArrowUp } from 'src/assets/icons';
import Avatar from 'src/components/ui/Avatar/Avatar';
import IconButton from 'src/components/ui/Button/IconButton';
import { useComment } from 'src/hooks/project/task/useCommentAction';
import { IUser } from 'src/types';
import { IComment } from 'src/types/entities';
import TaskCommentItem from '../comments/tasks/TaskCommentItem';
import TaskComments from '../comments/tasks/TaskComments';

interface ITaskCommentSectionProps {
  myData: IUser | null;
  comments: IComment[];
  collaborators: IUser[];
  userCollaborates: boolean;
}

const TaskCommentSection = (props: ITaskCommentSectionProps) => {
  const { myData, comments, collaborators, userCollaborates } = props;
  const [searchParams] = useSearchParams();
  const taskId = searchParams.get('taskId');

  const editorWrapperRef = useRef<HTMLDivElement>(null);
  const [editorWrapperHeight, setEditorWrapperHeight] = useState(46);

  const {
    control,
    handleSubmit,
    handleCreateComment,
    handleEditorChange,
    isCreatingComment,
    editorConfig,
  } = useComment({
    taskId: taskId as string,
    collaborators,
    editorPlaceholder: 'Leave a comment',
  });

  useEffect(() => {
    const updateEditorHeight = () => {
      if (editorWrapperRef.current) {
        const newHeight = editorWrapperRef.current.offsetHeight;
        if (newHeight !== editorWrapperHeight) {
          setEditorWrapperHeight(newHeight);
        }
      }
    };

    updateEditorHeight();

    window.addEventListener('resize', updateEditorHeight);

    return () => {
      window.removeEventListener('resize', updateEditorHeight);
    };
  }, [editorWrapperHeight]);

  return (
    <Fragment>
      <TaskComments
        comments={comments}
        renderComment={(comment: IComment) => (
          <TaskCommentItem
            taskId={taskId as string}
            myData={myData}
            comment={comment}
            collaborators={collaborators}
          />
        )}
      />

      <div className="w-full absolute z-5 pt-4 pb-8 px-5 bottom-0 left-0 border-t border-[var(--border-divider)] bg-[var(--backgrounds-on-canvas)]">
        <div className="w-full absolute h-[70px] top-[-71px] left-0 scrollBg_bottom" />
        <div className="flex items-end relative w-full">
          <div className="absolute left-2 pb-2.5">
            <Avatar
              name={myData?.detail?.fullName as string}
              src={myData?.detail.avatar?.url}
              alt={`${myData?.detail?.fullName} pfp`}
              size={24}
            />
          </div>

          <div
            ref={editorWrapperRef}
            className={`!w-full !bg-[var(--backgrounds-default)] shadow-none !pl-11 !pr-2 min-h-11
              ${
                editorWrapperHeight > 48 ? '!rounded-lg' : '!rounded-full'
              } transition-all duration-300
            `}
          >
            <div className="placeholder:!text-base placeholder:!font-medium placeholder:!text-[var(--text-disabled)] !w-full">
              <Controller
                name="comment"
                control={control}
                disabled={!userCollaborates}
                render={({ field }) => (
                  <CKEditor
                    {...field}
                    editor={BalloonEditor}
                    data={field.value}
                    config={editorConfig as any}
                    onChange={(event, editor) => {
                      field.onChange(editor.getData());
                      handleEditorChange(editor);
                      setTimeout(() => {
                        if (editorWrapperRef.current) {
                          setEditorWrapperHeight(
                            editorWrapperRef.current.offsetHeight
                          );
                        }
                      }, 0);
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div className="absolute right-2 pb-1.5">
            <IconButton
              type="primary"
              size="standard"
              rounded="full"
              icon={<ArrowUp />}
              onClick={handleSubmit((data) => handleCreateComment(data))}
              loading={isCreatingComment}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TaskCommentSection;
