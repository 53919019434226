import EmojiPicker, { Theme } from 'emoji-picker-react';
import React, { PropsWithChildren, useState } from 'react';
import { IconAddReaction } from 'src/assets/icons';
import IconButton from 'src/components/ui/Button/IconButton';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'src/components/ui/Popover';
import { cn } from 'src/lib/utils';

interface IEmojiPickerProps extends PropsWithChildren<{}> {
  value: string;
  onChange: (icon: string) => void;
  label: string;
  icon: React.ReactNode;
  className?: string;
}

const CustomEmojiPicker = (props: IEmojiPickerProps) => {
  const { onChange, className } = props;

  const [open, setOpen] = useState(false);

  return (
    <Popover onOpenChange={(isOpen) => setOpen(isOpen)}>
      <PopoverTrigger asChild>
        <div className="w-max">
          <IconButton
            type="ghost"
            size="small"
            rounded="small"
            open={open}
            icon={<IconAddReaction />}
          />
        </div>
      </PopoverTrigger>
      <PopoverContent
        className={cn(
          'min-w-[200px] border-[var(--border-on-bg)] rounded-md',
          className
        )}
        sideOffset={8}
      >
        <EmojiPicker
          theme={Theme.AUTO}
          onEmojiClick={(data) => onChange(data.emoji)}
          className="emoji-picker-customization"
        />
      </PopoverContent>
    </Popover>
  );
};

export default CustomEmojiPicker;
