import { useLazyQuery, useQuery } from '@apollo/client';
import {
  WORKSPACE,
  WORKSPACE_BY_INVITATION,
  WORKSPACE_INVITATION,
  WORKSPACE_PLAN,
  WORKSPACE_PLANS,
  WORKSPACES,
} from 'src/lib/queries';

export function useWorkspaces() {
  return useQuery(WORKSPACES, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-and-network' as const,
  });
}

export function useWorkspace(args: object) {
  return useQuery(WORKSPACE, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-and-network' as const,
    ...args,
  });
}

export function useWorkspacePlans(args: object) {
  return useQuery(WORKSPACE_PLANS, { ...args });
}

export function useWorkspacePlan(args: object) {
  return useQuery(WORKSPACE_PLAN, { ...args });
}

export function useLazyWorkspace() {
  return useLazyQuery(WORKSPACE);
}

export function useWorkSpaceByInvitation(args: object) {
  return useQuery(WORKSPACE_INVITATION, {
    ...args,
  });
}

export function useWorkSpaceByInvitationLink(args: object) {
  return useQuery(WORKSPACE_BY_INVITATION, {
    ...args,
  });
}
