import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReadOneInbox } from 'src/api/inbox/inbox.service';
import Avatar from 'src/components/ui/Avatar/Avatar';
import Timestamp from 'src/components/ui/TimeStamp';
import { colorStyles } from 'src/lib/colorPreset';
import { getColorTag } from 'src/lib/helpers';
import { ITaskInbox } from 'src/types/entities';
import {
  AddedToProject,
  CommentInMentionedPost,
  DeletedProject,
  IInbox,
  InboxType,
  MentionedInComment,
  MentionedInPost,
  NewPostCommentReplies,
  NewProjectPost,
  UpdatedProjectStatus,
} from '../../../lib/types';

export default function InboxItem({
  inbox,
  workspaceSlug,
}: {
  inbox: IInbox;
  workspaceSlug: string;
}) {
  const navigate = useNavigate();
  const [readOneInbox, { loading }] = useReadOneInbox();

  const renderProjectItem = (
    context: AddedToProject | UpdatedProjectStatus | DeletedProject
  ) => (
    <div className="p-2 flex items-start gap-2 bg-[var(--backgrounds-default)] rounded-lg border border-[var(--border-divider)]">
      <div
        className={`w-5 h-5 aspect-square flex flex-col items-center justify-center rounded text-body-micro bold`}
        style={colorStyles[getColorTag(context.projectName)]}
      >
        {context.projectName[0]}
      </div>
      <div>
        <p className="text-body-small medium text-[var(--text-neutral)] group-hover:text-[var(--text-neutral)]">
          {context.projectName}
        </p>
        <p
          className="ck ck-content text-body-micro regular text-[var(--text-neutral)]"
          style={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
            overflow: 'hidden',
          }}
          dangerouslySetInnerHTML={{ __html: context.projectDescription }}
        />
      </div>
    </div>
  );

  const renderPostItem = (title: string, body: string) => (
    <Fragment>
      <p className="text-body-micro medium text-[--text-on-bg] truncate">
        {title}
      </p>
      <p
        className="ck ck-content text-body-micro regular text-[--text-neutral] group-hover:text-[var(--text-on-bg)]"
        style={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
          overflow: 'hidden',
        }}
        dangerouslySetInnerHTML={{ __html: body }}
      />
    </Fragment>
  );

  const renderTaskItem = (title: string, body: string) => (
    <Fragment>
      <p className="text-body-micro medium text-[--text-on-bg] truncate">
        {title}
      </p>
      <p
        className="ck ck-content text-body-micro regular text-[--text-neutral] group-hover:text-[var(--text-on-bg)]"
        style={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
          overflow: 'hidden',
        }}
        dangerouslySetInnerHTML={{ __html: body }}
      />
    </Fragment>
  );

  const renderCommentItem = (comment: string) => (
    <p
      className="ck ck-content text-body-micro regular text-[--text-neutral] group-hover:text-[var(--text-on-bg)]"
      style={{
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        overflow: 'hidden',
      }}
      dangerouslySetInnerHTML={{ __html: comment }}
    />
  );

  const renderItem = () => {
    switch (inbox.type) {
      case InboxType.ADDED_TO_PROJECT:
      case InboxType.UPDATED_PROJECT_STATUS:
      case InboxType.DELETED_PROJECT:
      case InboxType.DELETED_PROJECT_PERMANENTLY:
        return renderProjectItem(
          inbox.context as
            | AddedToProject
            | UpdatedProjectStatus
            | DeletedProject
        );
      case InboxType.POSTED_ON_PROJECT:
        const postContext = inbox.context as NewProjectPost;
        return renderPostItem(postContext.postTitle, postContext.postBody);
      case InboxType.REPLIED_TO_COMMENT:
      case InboxType.COMMENTED_ON_POST:
        const commentContext = inbox.context as NewPostCommentReplies;
        return renderCommentItem(commentContext.commentReply);
      case InboxType.MENTIONED_IN_POST:
        const mentionContext = inbox.context as MentionedInPost;
        return renderPostItem(
          mentionContext.postTitle,
          mentionContext.postComment
        );
      case InboxType.COMMENTED_ON_MENTIONED_POST:
        const commentedMentionContext = inbox.context as CommentInMentionedPost;
        return renderPostItem(
          commentedMentionContext.postTitle,
          commentedMentionContext.comment
        );
      case InboxType.MENTIONED_IN_COMMENT:
        const mentionedCommentContext = inbox.context as MentionedInComment;
        return renderCommentItem(mentionedCommentContext.comment);
      case InboxType.ASSIGNED_TASK:
        const taskContext = inbox.context as ITaskInbox;
        return renderTaskItem(
          taskContext.taskTitle,
          taskContext.taskDescription
        );
      default:
        return null;
    }
  };

  const navigateToInboxItem = () => {
    let path = `/workspace/${workspaceSlug}`;
    const context = inbox.context as any;

    switch (inbox.type) {
      case InboxType.ADDED_TO_PROJECT:
      case InboxType.UPDATED_PROJECT_STATUS:
        path += `/project/${context.projectId}`;
        break;
      case InboxType.POSTED_ON_PROJECT:
      case InboxType.MENTIONED_IN_POST:
        path += `/project/${context.projectId}/posts?postId=${context.postId}`;
        break;
      case InboxType.COMMENTED_ON_POST:
      case InboxType.MENTIONED_IN_COMMENT:
      case InboxType.COMMENTED_ON_MENTIONED_POST:
        path += `/project/${context.projectId}/posts/${context.postId}?commentId=${context.commentId}`;
        break;
      case InboxType.REPLIED_TO_COMMENT:
        path += `/project/${context.projectId}/posts/${context.postId}`;
        break;
      case InboxType.ASSIGNED_TASK:
        path += `/project/${context.projectId}/tasks?taskId=${context.taskId}`;
        break;
      default:
        return;
    }

    navigate(path);
  };

  const handleClickItem = () => {
    navigateToInboxItem();
    if (!loading && !inbox.isRead) {
      readOneInbox({
        variables: {
          where: { id: inbox.id },
        },
        optimisticResponse: {
          __typename: 'Inbox',
          readOneInbox: {
            ...inbox,
            isRead: true,
          },
        },
      });
    }
  };

  return (
    <div
      onClick={handleClickItem}
      className="py-4 px-5 flex gap-2 items-start w-full group hover:bg-[var(--backgrounds-hover-clicked)] cursor-pointer"
    >
      <div>
        <Avatar
          name={inbox.sender.detail?.fullName}
          src={inbox.sender.detail.avatar?.url}
          alt={inbox.sender.detail?.fullName}
          size={20}
        />
      </div>
      <div className="flex flex-col gap-4 w-full">
        <div className="flex items-center gap-1">
          <div className="flex-1">
            <p className="truncate max-w-xs text-body-small medium text-[--text-neutral] group-hover:text-[var(--text-default)]">
              {inbox.sender.detail?.fullName} {inboxTypeForHumans(inbox.type)}
            </p>
          </div>
          <p className="text-body-micro regular text-[--text-neutral] group-hover:text-[var(--text-default)]">
            <Timestamp createdAt={inbox.createdAt} />
          </p>
          {!inbox.isRead ? (
            <div className="w-2 h-2 rounded-full bg-[#8178EF] ml-[11px]" />
          ) : null}
        </div>
        <div className="whitespace-break-spaces">{renderItem()}</div>
      </div>
    </div>
  );
}

/**
 *
 * @param inboxType
 */
function inboxTypeForHumans(inboxType: InboxType): string {
  switch (inboxType) {
    case InboxType.ADDED_TO_PROJECT:
      return 'added you to a project';

    case InboxType.UPDATED_PROJECT_STATUS:
      return 'updated a project status';

    case InboxType.DELETED_PROJECT:
      return 'deleted a project';

    case InboxType.DELETED_PROJECT_PERMANENTLY:
      return 'deleted a project permanently';

    case InboxType.MENTIONED_IN_COMMENT:
      return 'mentioned you in a comment';

    case InboxType.MENTIONED_IN_POST:
      return 'mentioned you in a post';

    case InboxType.COMMENTED_ON_POST:
      return 'commented on your post';

    case InboxType.COMMENTED_ON_MENTIONED_POST:
      return "commented on a post you're mentioned in";

    case InboxType.REPLIED_TO_COMMENT:
      return 'replied to your comment';

    case InboxType.POSTED_ON_PROJECT:
      return 'posted on your project';

    case InboxType.ASSIGNED_TASK:
      return 'assigned you a task';

    default:
      return inboxType;
  }
}
