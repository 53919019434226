import { Fragment } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Alert,
  ArrowBack,
  Bank,
  CreditCard,
  Lock,
  Mute,
  Plus,
  Projects,
  Rocket,
  Status,
  ThumbsDown,
  UserCircle,
  Users,
} from 'src/assets/icons';
import SideNavMenuBtn from 'src/components/sideNav/SideNavMenu';
import { useAuth } from 'src/context/auth.context';

export default function SettingSideNav() {
  const { workspaceSlug } = useParams();
  const navigate = useNavigate();
  const { logout } = useAuth();

  const WORKSPACE_URL_BASE = `/workspace/${workspaceSlug}`;

  return (
    <Fragment>
      <button onClick={() => navigate(-1)} className="w-full">
        <SideNavMenuBtn
          type="settings"
          label={'Back'}
          icon={<ArrowBack />}
          showActiveState={false}
        />
      </button>
      <div className="side_nav_group">
        <p className="nav_title text-body-micro bold">Workspace settings</p>
        <div className="menu_btn_group">
          <Link to={`${WORKSPACE_URL_BASE}/settings/general`} replace>
            <SideNavMenuBtn
              type="settings"
              label={'General'}
              icon={<Bank />}
              link={`${WORKSPACE_URL_BASE}/settings/general`}
            />
          </Link>
          <Link to={`${WORKSPACE_URL_BASE}/settings/members`} replace>
            <SideNavMenuBtn
              type="settings"
              label={'Members'}
              icon={<Users />}
              link={`${WORKSPACE_URL_BASE}/settings/members`}
            />
          </Link>
          <Link to={`${WORKSPACE_URL_BASE}/settings/security`} replace>
            <SideNavMenuBtn
              type="settings"
              label={'Security'}
              icon={<Lock />}
              link={`${WORKSPACE_URL_BASE}/settings/security`}
            />
          </Link>
          <Link to={`${WORKSPACE_URL_BASE}/settings/billing`} replace>
            <SideNavMenuBtn
              type="settings"
              label={'Billing'}
              icon={<CreditCard />}
              link={`${WORKSPACE_URL_BASE}/settings/billing`}
            />
          </Link>
          <Link to={`${WORKSPACE_URL_BASE}/settings/projects`} replace>
            <SideNavMenuBtn
              type="settings"
              label={'Projects'}
              icon={<Projects className="w-4 h-4" />}
              link={`${WORKSPACE_URL_BASE}/settings/projects`}
            />
          </Link>
        </div>
      </div>
      <div className="side_nav_group">
        <p className="nav_title text-body-micro bold">Account settings</p>
        <div className="menu_btn_group">
          <Link to={`${WORKSPACE_URL_BASE}/settings/profile`} replace>
            <SideNavMenuBtn
              type="settings"
              label={'My profile'}
              icon={<UserCircle />}
              link={`${WORKSPACE_URL_BASE}/settings/profile`}
            />
          </Link>
          <Link to={`${WORKSPACE_URL_BASE}/settings/preference`} replace>
            <SideNavMenuBtn
              type="settings"
              label={'Preference'}
              icon={<Status />}
              link={`${WORKSPACE_URL_BASE}/settings/preference`}
            />
          </Link>
          <Link to={`${WORKSPACE_URL_BASE}/settings/notifications`} replace>
            <SideNavMenuBtn
              type="settings"
              label={'Notifications'}
              icon={<Alert className="w-4 h-4" />}
              link={`${WORKSPACE_URL_BASE}/settings/notifications`}
            />
          </Link>
        </div>
      </div>
      <div className="side_nav_group">
        <p className="nav_title text-body-micro bold">Others & support</p>
        <div className="menu_btn_group">
          <Link to={`/create-workspace`} replace>
            <SideNavMenuBtn
              type="settings"
              label={'Create workspace'}
              icon={<Plus />}
              link={`/create-workspace`}
            />
          </Link>
          <Link to={`${WORKSPACE_URL_BASE}/settings/what's-new`} replace>
            <SideNavMenuBtn
              type="settings"
              label={'What’s new'}
              icon={<Rocket />}
              link={`${WORKSPACE_URL_BASE}/settings/what's-new`}
            />
          </Link>
          <a href="mailto:hi@overlap.work">
            <SideNavMenuBtn
              type="settings"
              label={'Feedback'}
              icon={<Mute />}
              link={``}
              showActiveState={false}
            />
          </a>
          <div role="button" className="w-full" onClick={() => logout()}>
            <SideNavMenuBtn
              type="settings"
              label={'Log out'}
              icon={<ThumbsDown />}
              link={`${WORKSPACE_URL_BASE}/settings/log-out`}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
