import { Fragment } from 'react';
import Avatar from 'src/components/ui/Avatar/Avatar';
import { transformToTitleCase } from 'src/lib/helpers';
import { cn } from 'src/lib/utils';

interface UserDetailItemProps {
  userName: string;
  userPosition: string;
  avatarUrl?: string;
  avatarSize?: number;
  wrapItem?: boolean;
}

const UserDetailItem = ({
  userName,
  userPosition,
  avatarUrl,
  avatarSize = 28,
  wrapItem = true,
  ...rest
}: UserDetailItemProps) => {
  return (
    <Fragment>
      {wrapItem ? (
        <div
          className={cn(
            'w-full flex items-center justify-between px-2 hover:bg-[var(--backgrounds-hover-clicked)] cursor-pointer rounded'
          )}
          {...rest}
        >
          <div className="flex items-center gap-x-2 py-[2.5px]">
            <Avatar
              name={userName}
              src={avatarUrl}
              alt={userName}
              size={avatarSize}
            />
            <div className="flex-1">
              <div className="text-body-small medium text-[var(--text-default)] max-w-[150px] truncate">
                {userName}
              </div>
              <div className="text-body-micro regular text-[var(--text-neutral)]">
                {transformToTitleCase(userPosition)}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center gap-x-2 py-[2.5px]">
          <Avatar
            name={userName}
            src={avatarUrl}
            alt={userName}
            size={avatarSize}
          />
          <div className="flex-1">
            <div className="text-body-small medium text-[var(--text-default)] max-w-[150px] truncate">
              {userName}
            </div>
            <div className="text-body-micro regular text-[var(--text-neutral)]">
              {transformToTitleCase(userPosition)}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default UserDetailItem;
