import parse from 'html-react-parser';
import React from 'react';
import './ui.css';
interface HeaderTextProps {
  title?: string;
  badge?: React.ReactNode;
  subTitle?: string;
  snippet?: string;
}

const HeaderText: React.FC<HeaderTextProps> = ({
  title,
  badge,
  subTitle,
  snippet,
}) => {
  return (
    <div className="header_text">
      {title && (
        <div className="flex items-center gap-x-2 w-fit">
          <h2 className="title text-heading-4 medium">{title}</h2>
          {badge}
        </div>
      )}
      {subTitle && (
        <h3 className="subtitle text-body-small medium">{subTitle}</h3>
      )}
      {snippet && (
        <p className="snippet text-body-small regular">{parse(snippet)}</p>
      )}
    </div>
  );
};

export default HeaderText;
