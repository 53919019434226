import 'non.geist';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import PageRoutes from './pages/PageRoutes';

import '../node_modules/ckeditor5/dist/index.css';
import './App.css';
import './assets/css/global.css';
import './assets/css/tweaks.css';

import { AuthProvider } from './context/auth.context';
import { ModalProvider } from './context/modalContext';
import { ThemeProvider } from './context/themeContext';
import { ApolloProvider } from './provider/ApolloProvider';

function App() {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <AuthProvider>
          <ApolloProvider>
            <ModalProvider>
              <Toaster
                position="bottom-right"
                containerClassName={'mb-6'}
                toastOptions={{
                  className:
                    'flex max-w-[406px] w-full p-[8px] px-[6px] items-center gap-[16px] rounded-[8px] border border-[var(--border-default)] !bg-[var(--backgrounds-dropdowns)] shadow-[0px_3px_2px_0px_rgba(7,7,7,0.05)]',
                  duration: 5000,
                }}
              />
              <PageRoutes />
            </ModalProvider>
          </ApolloProvider>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
