import './ui.css';

interface HorizontalDividerProps {
  size?: 'large';
  className?: string;
}

const HorizontalDivider: React.FC<HorizontalDividerProps> = ({
  size = 'small',
  className,
}) => {
  return (
    <div className={`hr ${size === 'large' ? 'large' : 'small'} ${className}`}>
      &nbsp;
    </div>
  );
};

export default HorizontalDivider;
