import { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Plus } from 'src/assets/icons';
import SectionWrap from 'src/components/common/SectionWrap';
import SideNavMenuBtn from 'src/components/sideNav/SideNavMenu';
import IconButton from 'src/components/ui/Button/IconButton';
import MobileWorkSpaceMenu from 'src/components/ui/MobileWorkSpaceMenu';

import { useWorkspaceContext } from 'src/context/workspace.context';
import useMobile from 'src/hooks/useMobile';
import { colorStyles } from 'src/lib/colorPreset';
import { getColorTag } from 'src/lib/helpers';
import { IProject } from 'src/types';
import ProjectModal from '../components/projects/ProjectModal';

function MobileHome() {
  const navigate = useNavigate();
  const isMobile = useMobile();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();

  const { workspaceProjects } = useWorkspaceContext();
  const [createProject, setCreateProject] = useState(false);

  const WORKSPACE_URL_BASE = `/workspace/${workspaceSlug}/project`;

  useEffect(() => {
    if (workspaceProjects && workspaceProjects?.length > 0) {
      navigate(
        `/workspace/${workspaceSlug}/project/${
          isMobile ? 'all' : workspaceProjects[0]?.id
        }`
      );
    } else {
      navigate(`/workspace/${workspaceSlug}/project/`);
    }
  }, [workspaceProjects, workspaceSlug, navigate, isMobile]);

  return (
    <Fragment>
      <SectionWrap className="">
        <div className="page-title z-1 flex items-center justify-between md:hidden">
          <MobileWorkSpaceMenu />
          <p className="tet-body-small bold text-[var(--text-neutral)]">
            Projects
          </p>
          <IconButton
            icon={<Plus />}
            size={'small'}
            type={'ghost'}
            onClick={() => setCreateProject(true)}
          />
        </div>
        <div className="flex flex-col gap-2 w-full mt-20 pt-1.5 px-4">
          {workspaceProjects?.map((project: IProject) => (
            <Link key={project.id} to={`${WORKSPACE_URL_BASE}/${project.id}`}>
              <SideNavMenuBtn
                avatarColor={colorStyles[getColorTag(project.name)]}
                sideAvatar={project.name}
                type="home"
                label={project.name}
                link={`${WORKSPACE_URL_BASE}/${project.id}`}
                showBadge={false}
              />
            </Link>
          ))}
        </div>
      </SectionWrap>

      <ProjectModal
        show={createProject}
        handleClose={() => setCreateProject(false)}
      />
    </Fragment>
  );
}

export default MobileHome;
