import { useDebounce, useLocalStorage } from '@uidotdev/usehooks';
import { ChangeEvent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import ToastMessage from 'src/components/ToastMessage';
import Button from 'src/components/ui/Button/Button';
import CheckBox from 'src/components/ui/CheckBox';
import HeaderText from 'src/components/ui/HeaderTexts';
import HorizontalDivider from 'src/components/ui/HorizontalDivider';
import Input from 'src/components/ui/inputs/Default';
import Logo from 'src/components/ui/Logo';
import Modal from 'src/components/ui/Modals/Modal';
import UrlInput from 'src/components/UrlInput';
import ValidationErrorMessages from 'src/components/ValidationErrorMessages';
import { useUser } from 'src/context/user.context';
import { useWorkspaceContext } from 'src/context/workspace.context';
import usePrevious from 'src/hooks/usePrevious';
import Validator from 'validatorjs';
import {
  useDeleteOneWorkspace,
  useLazyWorkspace,
  useUpdateOneWorkspace,
  useVerifyDeleteOneWorkspace,
} from '../../../api/workspace';
import '../settings.css';

type FormData = {
  logo?: File;
  name: string;
  slug: string;
  website: string;
};

export default function General() {
  const navigate = useNavigate();
  const { user } = useUser();
  const { workspace } = useWorkspaceContext();
  const [userId] = useLocalStorage('userId', undefined);

  // GraphQL definition
  const { workspaceSlug } = useParams<{
    projectId: string;
    workspaceSlug: string;
  }>();
  const prevWorkspaceSlug = usePrevious<string | undefined>(workspaceSlug);

  const [
    updateOneWorkspace,
    { loading: updateOneWorkspaceLoading, error: updateOneWorkspaceError },
  ] = useUpdateOneWorkspace();
  const [
    deleteOneWorkspace,
    { loading: deleteOneWorkspaceLoading, error: deleteOneWorkspaceError },
  ] = useDeleteOneWorkspace();
  const [
    verifyDeleteOneWorkspace,
    {
      loading: verifyDeleteOneWorkspaceLoading,
      error: verifyDeleteOneWorkspaceError,
    },
  ] = useVerifyDeleteOneWorkspace();
  const errorMessage =
    updateOneWorkspaceError &&
    `Submission error! ${updateOneWorkspaceError.message}`;

  // state definition
  const [verifyToken, setVerifyToken] = useState('');

  // modal states
  const [deleteModal, showDeleteModal] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteConfirmationModal, showDeleteConfirmationModal] =
    useState(false);

  const [formData, setFormData] = useState<FormData>({
    name: workspace?.name ?? '',
    slug: workspace?.slug ?? '',
    website: workspace?.website ?? '',
  });
  const [isSlugManuallyEdited, setIsSlugManuallyEdited] = useState(false);

  const debouncedFormData = useDebounce(formData, 300);
  const prevFormData = usePrevious<FormData>(formData);
  const [formErrors, setFormErrors] =
    useState<Validator.ValidationErrors | null>(null);

  useEffect(() => {
    if (workspace) {
      setFormData({
        name: workspace.name,
        slug: workspace.slug,
        website: workspace.website,
      });
    }
  }, [workspace]);

  const [
    existingWorkspace,
    {
      data: existingWorkspaceData,
      loading: existingWorkspaceLoading,
      error: existingWorkspaceError,
    },
  ] = useLazyWorkspace();

  useEffect(() => {
    if (debouncedFormData.slug && debouncedFormData.slug !== workspaceSlug) {
      existingWorkspace({
        variables: { where: { slug: debouncedFormData.slug } },
        fetchPolicy: 'network-only',
      });
    }
  }, [
    debouncedFormData.slug,
    existingWorkspace,
    prevWorkspaceSlug,
    workspaceSlug,
  ]);

  // This can be easily managed with RHF lib, coming back to refactor #reminder

  const handleInputChange =
    (field: string) => (e: ChangeEvent<HTMLInputElement> | any) => {
      let value;

      try {
        value = e.target.value;
      } catch (error) {
        value = e;
      }

      setFormData({ ...formData, [field]: value });
      setFormErrors(null);
    };

  const handleSubmit = async () => {
    const validation = new Validator(formData, {
      name: 'required',
      slug: 'required',
      website: 'required|url',
    });

    if (validation.passes()) {
      setFormErrors(null);
      updateOneWorkspace({
        variables: {
          data: {
            name: { set: formData.name },
            slug: { set: formData.slug },
            website: { set: formData.website },
          },
          where: {
            slug: workspaceSlug,
          },
        },
        onCompleted: ({ updateOneWorkspace }) => {
          toast((t) => (
            <ToastMessage
              id={t.id}
              title={`Workspace updated successfully`}
              buttonAction={() => {
                toast.dismiss(t.id);
              }}
            />
          ));
          navigate(`/workspace/${updateOneWorkspace.slug}/settings/general`, {
            replace: true,
          });
        },
        onError: (error) => {
          toast.error(`Error updating workspace: ${error.message}`);
        },
      });
    } else {
      setFormErrors(validation.errors.all());
    }
  };

  return (
    <div className="workspace-page flex flex-col gap-10">
      <div className="header_div">
        <HeaderText title="General" />
        <HorizontalDivider />
      </div>
      <div className="flex flex-col gap-3 items-start">
        <HeaderText subTitle="Logo" />
        <Logo
          logo={workspace?.logo}
          userId={userId}
          name={workspace?.name}
          size={40}
          fontSize={20}
        />
        <p className="general-logo-p text-body-micro">
          Upload a logo for your workspace
        </p>
      </div>
      <HorizontalDivider />
      <div className="profile_details flex flex-col gap-6 items-start w-full">
        <HeaderText
          subTitle="Workspace details"
          snippet="Update your workspace name and URL."
        />
        <div className="w-full space-y-2">
          <label
            className="text-body-small regular text-[var(--text-default)]"
            htmlFor="workspace-name"
          >
            Workspace name
          </label>
          <input
            id="workspace-name"
            placeholder="Example Inc."
            className="w-full input"
            value={formData.name}
            onChange={handleInputChange('name')}
          />
          <ValidationErrorMessages name="name" errors={formErrors} />
        </div>

        <div className="w-full space-y-2">
          <label
            className="text-body-small regular text-[var(--text-default)]"
            htmlFor="workspace-url"
          >
            Workspace URL
          </label>
          <UrlInput
            id="workspace-url"
            slugOnly
            value={formData.slug}
            onChange={handleInputChange('slug')}
            domain="overlap.work"
            loading={existingWorkspaceLoading}
            success={Boolean(existingWorkspaceError)}
            error={Boolean(existingWorkspaceData)}
            generateFrom={!isSlugManuallyEdited ? formData.name : undefined}
            onManualEdit={() => setIsSlugManuallyEdited(true)}
          />
          <ValidationErrorMessages name="slug" errors={formErrors} />
        </div>

        <div className="w-full space-y-2">
          <label
            className="text-body-small regular text-[var(--text-default)]"
            htmlFor="workspace-website"
          >
            Website
          </label>
          <UrlInput
            id="workspace-website"
            value={formData.website}
            onChange={handleInputChange('website')}
            placeholder="www.example.com"
          />
          <ValidationErrorMessages name="website" errors={formErrors} />
        </div>

        {errorMessage && (
          <p className="text-xs text-red-500 text-center">{errorMessage}</p>
        )}

        <div className="self-end">
          <Button
            btnType={'primary'}
            value={'Update'}
            size={'medium'}
            className={'w-auto'}
            onClick={handleSubmit}
            loading={updateOneWorkspaceLoading}
            disabled={updateOneWorkspaceLoading}
          />
        </div>
      </div>
      <HorizontalDivider />
      <div className="flex flex-col items-start gap-6 w-full">
        <HeaderText
          subTitle="Danger zone"
          snippet="You cannot undo this action if you continue."
        />
        <div className="section-wrap flex items-center gap-4 justify-between p-4 w-full">
          <div className="flex flex-col items-start gap-0.5">
            <p className="title text-body-large medium">
              Delete this workspace
            </p>
            <p className="snippet text-body-small regular">
              This workspace will be permanently deleted along with all of its
              data
            </p>
          </div>
          <Button
            btnType={'destructive'}
            value={'Delete workspace'}
            size={'medium'}
            className={'w-auto'}
            onClick={() => showDeleteModal(true)}
          />
        </div>
      </div>

      {/* Delete Modal */}
      <Modal
        open={deleteModal}
        data={{
          subtitle: 'Delete this workspace?',
          snippet:
            'Before proceeding, please note that deleting your workspace is irreversible and will permanently remove all data associated with it. <br /><br /> This action cannot be undone, all files, documents, and information stored within the workspace will be permanently deleted. <br /><br /> This includes any content that has not been exported or backed up.',
          btn1: 'Cancel',
          btn2: 'Delete workspace',
          btn1Type: 'neutral',
          btn2Type: 'destructive',
        }}
        onBtn1Click={() => {
          showDeleteModal(false);
          setDeleteConfirm(false);
        }}
        onBtn2Click={() => {
          deleteOneWorkspace({
            variables: {
              where: { slug: workspaceSlug },
            },
            onCompleted: () => {
              showDeleteModal(false);
              setDeleteConfirm(false);
              showDeleteConfirmationModal(true);
            },
          });
        }}
        btn2Disabled={!deleteConfirm || deleteOneWorkspaceLoading}
        loading={deleteOneWorkspaceLoading}
      >
        <div className="section-wrap flex items-center gap-4 justify-between p-4 w-full">
          <div className="flex items-start gap-2">
            <div className=" py-[3px]">
              <CheckBox
                type="default"
                isChecked={deleteConfirm}
                onCheckboxChange={() => setDeleteConfirm(!deleteConfirm)}
              />
            </div>
            <p className="snippet text-body-small medium">
              By confirming your decision, you acknowledge and accept the risks
              involved.
            </p>
          </div>
        </div>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        open={deleteConfirmationModal}
        data={{
          subtitle: 'Verify this action',
          snippet: `Enter the OTP we just sent to <span>${user?.email}</span> to confirm this action.`,
          btn1: 'Cancel',
          btn2: 'Delete',
          btn1Type: 'neutral',
          btn2Type: 'destructive',
        }}
        onBtn1Click={() => {
          showDeleteConfirmationModal(false);
        }}
        onBtn2Click={() => {
          verifyDeleteOneWorkspace({
            variables: {
              where: { slug: workspaceSlug },
              data: {
                email: user?.email,
                token: verifyToken,
              },
            },
            onCompleted: () => {
              navigate('/');
            },
          });
        }}
        btn2Disabled={verifyDeleteOneWorkspaceLoading}
        loading={verifyDeleteOneWorkspaceLoading}
      >
        <Input
          type="text"
          value={verifyToken}
          feedback={
            verifyDeleteOneWorkspaceError
              ? verifyDeleteOneWorkspaceError.message
              : ''
          }
          feedbackType={verifyDeleteOneWorkspaceError ? 'error' : 'success'}
          label="Enter OTP"
          placeholder="Enter your OTP"
          onChange={(e) => setVerifyToken(e.target.value)}
        />
      </Modal>
    </div>
  );
}
