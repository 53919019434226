import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Edit, ThreeDots, Trash } from 'src/assets/icons';
import IconButton from 'src/components/ui/Button/IconButton';
import { DropItem } from 'src/components/ui/DropGroup';

function TaskCommentMenu(props: {
  handleEdit: Function;
  handleDelete: Function;
  canEdit: boolean;
  canDelete: boolean;
  type?: 'post' | 'comment' | 'reply';
}) {
  const {
    handleEdit,
    handleDelete,
    canDelete = false,
    canEdit = false,
    type = 'post',
  } = props;

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <div>
            <Menu.Button>
              <IconButton
                open={open}
                size="micro"
                type="ghost"
                rounded="small"
                icon={<ThreeDots />}
              />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="drop-group absolute z-10 right-0 mt-2 w-48 origin-top-right">
              <div className="px-1 py-1 ">
                {canEdit && (
                  <Menu.Item>
                    {({ active }) => (
                      <button>
                        <DropItem
                          item={{
                            name: `Edit ${type}`,
                            icon: <Edit />,
                          }}
                          onItemClick={() => handleEdit()}
                        />
                      </button>
                    )}
                  </Menu.Item>
                )}
                {canDelete && (
                  <Menu.Item>
                    {({ active }) => (
                      <button>
                        <DropItem
                          item={{
                            name: `Delete ${type}`,
                            icon: <Trash />,
                          }}
                          onItemClick={() => handleDelete()}
                        />
                      </button>
                    )}
                  </Menu.Item>
                )}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}

export default TaskCommentMenu;
