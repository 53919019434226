import { Fragment } from 'react';
import { ChatBubble } from 'src/assets/icons';
import HorizontalDivider from 'src/components/ui/HorizontalDivider';
import { cn } from 'src/lib/utils';
import { IComment } from 'src/types/entities';

function PostComments({
  comments,
  renderComment,
}: {
  comments: IComment[];
  renderComment: (comment: IComment) => JSX.Element;
}) {
  if (!!comments.length) {
    return (
      <ul className="flex flex-col w-full items-center">
        {comments.map((comment, index) => (
          <Fragment>
            <li
              key={comment.id}
              className={cn('flex flex-col gap-2.5 w-full py-5 max-w-[54%]')}
            >
              {renderComment(comment)}
            </li>
            {index < comments.length - 1 && <HorizontalDivider />}
          </Fragment>
        ))}
      </ul>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center gap-1 w-full max-w-[54%] h-full">
      <ChatBubble className="h-8 w-8" />
      <h6 className="text-heading-6 medium text-[var(--text-default)]">
        No comments yet
      </h6>
      <p className="text-body-small regular text-[var(--text-neutral)]">
        Add the first comment
      </p>
    </div>
  );
}

export default PostComments;
