import { Outlet } from 'react-router-dom';
import { WorkspaceContextProvider } from 'src/context/workspace.context';

export default function WorkspaceLayout() {
  return (
    <WorkspaceContextProvider>
      <Outlet />
    </WorkspaceContextProvider>
  );
}
