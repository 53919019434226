import clsx from 'clsx';
import Spinner from '../spinner/circle-spinner/Spinner';
import '../ui.css';

export default function Button({
  children,
  value,
  size,
  type,
  btnType,
  icon,
  className,
  disabled,
  loading,
  onClick,
}: {
  children?: React.ReactNode;
  value: number | string;
  size: 'large' | 'medium' | 'small';
  btnType: 'primary' | 'neutral' | 'ghost' | 'destructive';
  type?: 'submit' | 'reset' | 'button' | undefined;
  icon?: 'right' | 'left';
  loading?: boolean;
  className?: any;
  disabled?: boolean;
  onClick?: () => void;
}) {
  return (
    <button
      disabled={disabled}
      type={type}
      className={clsx(`relative ${size} ${btnType} icon-${icon} ${className}`, {
        'text-body-large bold': size === 'large',
        'text-body-small': size === 'medium',
        'text-body-micro': size === 'small',
      })}
      onClick={onClick}
    >
      {loading && (
        <div className="bg-[inherit] absolute inset-0 grid place-items-center">
          <Spinner />
        </div>
      )}
      <span>{value}</span>
      {children}
    </button>
  );
}
