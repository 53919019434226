import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { SEND_AUTH_TOKEN } from 'src/lib/mutations';
import { ME } from 'src/lib/queries';

export function useMe(args?: object) {
  return useQuery(ME, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-and-network',
    ...args,
  });
}

export function useLazyMe(args?: object) {
  return useLazyQuery(ME, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-and-network',
    ...args,
  });
}

export function useSendAuthToken() {
  return useMutation(SEND_AUTH_TOKEN);
}
