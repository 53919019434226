import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useCreateOneProjectTopic } from 'src/api/project/project.service';
import { useWorkspace, useWorkspacePlans } from 'src/api/workspace';
import ToastMessage from 'src/components/ToastMessage';
import PageLoader from 'src/components/ui/Loader/PageLoader';
import {
  IInvitation,
  IProject,
  IProjectTopic,
  IUserWorkspace,
  IWorkspace,
  IWorkspaceMember,
  IWorkspacePlan,
} from 'src/types';
import { useUser } from './user.context';

interface WorkspaceContextType {
  refetchWorkspace: () => void;
  isLoadingWorkspace: boolean;
  workspace: IWorkspace | null;
  workspacePlans: IWorkspacePlan[] | null;
  userWorkspaces: IUserWorkspace[] | null;
  currentWorkspace: IUserWorkspace | null;
  workspaceProjects: IProject[] | null;
  currentWorkspaceProject: IProject | null;
  workspaceWorkstreams: IProjectTopic[] | null;
  handleCreateWorkstream: (name: string) => void;
  isCreatingWorkstream: boolean;
  workspaceMembers: () => IWorkspaceMember[] | null;
  workspaceInvitations: () => IInvitation[] | null;
  workspaceDeactivatedMembers: () => IWorkspaceMember[] | null;
}

const WorkspaceContext = createContext<WorkspaceContextType | undefined>(
  undefined
);

// #reminder: i have to refactor this context file (abstract)

export const WorkspaceContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { workspaceSlug, projectId } = useParams<{
    workspaceSlug: string;
    projectId: string;
  }>();

  const { user, handleSkipUserAction } = useUser();
  const userWorkspaces = user?.userWorkspaces;

  const [workspaceWorkstreams, setWorkspaceWorkstreams] = useState<
    IProjectTopic[]
  >([]);

  const currentWorkspaceFunc = useCallback(() => {
    return userWorkspaces?.find(
      (userWorkspace: IUserWorkspace) =>
        userWorkspace.workspace.slug === workspaceSlug
    );
  }, [workspaceSlug, userWorkspaces]);
  const currentWorkspace = currentWorkspaceFunc();

  const queryVariables = useMemo(
    () => ({
      where: { slug: workspaceSlug },
    }),
    [workspaceSlug]
  );
  const skipWorkspaceQuery = !workspaceSlug;

  const {
    loading: isLoadingWorkspace,
    data: currentWorkspaceData,
    refetch,
  } = useWorkspace({
    variables: queryVariables,
    skip: skipWorkspaceQuery,
    onError: (error: any) => {
      toast((t) => <ToastMessage id={t.id} title={`${error.message}`} />);
    },
  });

  const { data: workspacePlansData } = useWorkspacePlans({
    skip: skipWorkspaceQuery,
  });

  const shouldSkipUserAction = useCallback(() => {
    if (!user || !currentWorkspaceData) return false;

    const { actionStatus } = user;
    const isOwner = currentWorkspaceData.isOwner;

    return (
      !isOwner &&
      !actionStatus.hasVerifiedDomain &&
      actionStatus.hasCreatedProject &&
      actionStatus.hasInvitedTeamMembers &&
      actionStatus.hasCreatedPost
    );
  }, [user, currentWorkspaceData]);

  const currentWorkspaceMembers = useCallback(() => {
    return currentWorkspaceData?.workspace?.members?.filter(
      (member: IUserWorkspace) => !member.deactivatedAt
    );
  }, [currentWorkspaceData]);

  const memberExists = useCallback(
    (email: string) => {
      return currentWorkspaceMembers()?.some(
        (m: IUserWorkspace) => m.user.email === email
      );
    },
    [currentWorkspaceMembers]
  );

  const currentWorkspaceInvitations = useCallback(() => {
    return currentWorkspaceData?.workspace?.invitations?.filter(
      (invitation: IInvitation) => !memberExists(invitation.inviteeEmail)
    );
  }, [currentWorkspaceData, memberExists]);

  const currentWorkspaceDeactivatedMembers = useCallback(() => {
    return currentWorkspaceData?.workspace?.members?.filter(
      (member: IUserWorkspace) => !!member.deactivatedAt
    );
  }, [currentWorkspaceData]);

  const currentWorkspaceProjectFunc = useCallback(() => {
    return currentWorkspaceData?.workspace?.projects.find(
      (project: IProject) => project?.id === projectId
    );
  }, [currentWorkspaceData?.workspace?.projects, projectId]);
  const currentWorkspaceProject = currentWorkspaceProjectFunc();

  const [createOneProjectTopic, { loading: isCreatingWorkstream }] =
    useCreateOneProjectTopic();

  const handleCreateWorkstream = useCallback(
    async (name: string) => {
      try {
        const result = await createOneProjectTopic({
          variables: {
            data: {
              name: name,
              workspace: { connect: { slug: workspaceSlug } },
            },
          },
        });

        if (result.data && result.data.createOneProjectTopic) {
          const newWorkstream = result.data.createOneProjectTopic;
          setWorkspaceWorkstreams((prevWorkstreams) => [
            ...prevWorkstreams,
            {
              id: newWorkstream.id,
              name: newWorkstream.name,
            },
          ]);
        }

        toast((t) => (
          <ToastMessage
            id={t.id}
            title={`Workstream "${name}" created successfully`}
          />
        ));
      } catch (error: any) {
        toast((t) => (
          <ToastMessage id={t.id} title={`Error: ${error.message}`} />
        ));
      }
    },
    [createOneProjectTopic, workspaceSlug]
  );

  const refetchWorkspace = useCallback(() => {
    if (workspaceSlug && !skipWorkspaceQuery) {
      refetch(queryVariables);
    }
  }, [workspaceSlug, skipWorkspaceQuery, refetch, queryVariables]);

  useEffect(() => {
    if (!skipWorkspaceQuery) {
      refetchWorkspace();
    }
  }, [refetchWorkspace, skipWorkspaceQuery]);

  useEffect(() => {
    if (currentWorkspaceData?.workspace?.topics) {
      setWorkspaceWorkstreams(currentWorkspaceData.workspace.topics);
    }
  }, [currentWorkspaceData]);

  useEffect(() => {
    if (shouldSkipUserAction()) {
      handleSkipUserAction();
    }
  }, [shouldSkipUserAction, handleSkipUserAction]);

  const contextValue = useMemo<WorkspaceContextType>(
    () => ({
      workspace: currentWorkspaceData?.workspace || null,
      workspacePlans: workspacePlansData?.workspacePlans || null,
      userWorkspaces: userWorkspaces || null,
      currentWorkspace: currentWorkspace || null,
      isLoadingWorkspace: isLoadingWorkspace,
      workspaceProjects: currentWorkspaceData?.workspace?.projects || null,
      currentWorkspaceProject: currentWorkspaceProject || null,
      workspaceWorkstreams: workspaceWorkstreams,
      handleCreateWorkstream,
      isCreatingWorkstream,
      workspaceMembers: currentWorkspaceMembers,
      workspaceInvitations: currentWorkspaceInvitations,
      workspaceDeactivatedMembers: currentWorkspaceDeactivatedMembers,
      refetchWorkspace,
    }),
    [
      currentWorkspaceData?.workspace,
      workspacePlansData?.workspacePlans,
      userWorkspaces,
      currentWorkspace,
      isLoadingWorkspace,
      currentWorkspaceProject,
      workspaceWorkstreams,
      handleCreateWorkstream,
      isCreatingWorkstream,
      currentWorkspaceMembers,
      currentWorkspaceInvitations,
      currentWorkspaceDeactivatedMembers,
      refetchWorkspace,
    ]
  );

  return (
    <WorkspaceContext.Provider value={contextValue}>
      {isLoadingWorkspace ? <PageLoader /> : children}
    </WorkspaceContext.Provider>
  );
};

export const useWorkspaceContext = () => {
  const context = useContext(WorkspaceContext);
  if (context === undefined) {
    throw new Error(
      'useWorkspaceContext must be used within a WorkspaceProvider'
    );
  }
  return context;
};
