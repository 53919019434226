import { useMutation } from '@apollo/client';
import {
  CREATE_ONE_POST_COMMENT,
  DELETE_ONE_POST_COMMENT,
  UPDATE_ONE_POST_COMMENT,
} from 'src/lib/mutations';

export function useCreateOnePostComment() {
  return useMutation(CREATE_ONE_POST_COMMENT);
}

export function useUpdateOnePostComment() {
  return useMutation(UPDATE_ONE_POST_COMMENT);
}

export function useDeleteOnePostComment() {
  return useMutation(DELETE_ONE_POST_COMMENT);
}
