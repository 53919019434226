import { useMutation } from '@apollo/client';
import {
  CREATE_CHECKOUT_SESSION,
  GENERATE_UPLOAD_SIGNATURE,
} from 'src/lib/mutations';

export function useGenerateUploadSignature() {
  return useMutation(GENERATE_UPLOAD_SIGNATURE);
}

export function useCreateCheckoutSession() {
  return useMutation(CREATE_CHECKOUT_SESSION);
}
