const isClient = typeof window !== 'undefined';

export const webStorage = {
  setItem(key: string, value: any) {
    if (isClient) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  },
  getItem(key: string) {
    if (isClient) {
      const value = localStorage.getItem(key);
      return value ? JSON.parse(value) : null;
    }
    return null;
  },
  removeItem(key: string) {
    if (isClient) {
      localStorage.removeItem(key);
    }
  },
};
