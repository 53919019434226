import { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useJoinOneWorkspace } from 'src/api/workspace';
import { Plus } from 'src/assets/icons';
import Media from 'src/components/Media';
import Button from 'src/components/ui/Button/Button';
import HorizontalDivider from 'src/components/ui/HorizontalDivider';
import Spinner from 'src/components/ui/spinner/circle-spinner/Spinner';
import { useUser } from 'src/context/user.context';
import { IWorkspace } from 'src/lib/types';

// #reminder check this page also - refactor

function JoinWorkspacePage() {
  const navigate = useNavigate();
  const { user, userSetupComplete } = useUser();

  const [joinOneWorkspace, workspaceStatus] = useJoinOneWorkspace();
  const [loadingWorkspaceIDs, setLoadingWorkspaceIDs] = useState<Array<string>>(
    []
  );

  const handleJoinWorkspace = (workspace: IWorkspace) => () => {
    if (isMember(workspace)) {
      navigate(`/workspace/${workspace.slug}`);
    } else {
      setLoadingWorkspaceIDs([...loadingWorkspaceIDs, workspace.id]);
      joinOneWorkspace({
        variables: { where: { id: workspace.id } },
        onCompleted: () => {
          setLoadingWorkspaceIDs(
            loadingWorkspaceIDs.filter((id) => id !== workspace.id)
          );

          if (!userSetupComplete) {
            navigate(`/workspace/${workspace.slug}/get-started`);
            return;
          }

          navigate(`/workspace/${workspace.slug}`);
        },
        onError: (error) => {
          console.error(error);
        },
      });
    }
  };

  const isMember = (workspace: IWorkspace) => {
    return workspace.members.some((member) => member.userId === user?.id);
  };

  const isInvitee = (workspace: IWorkspace) => {
    return workspace.invitations.some(
      (invite) => invite.inviteeEmail === user?.email
    );
  };

  const existingWorkspaces = user?.userWorkspaces.map(
    ({ workspace }: { workspace: IWorkspace }) => workspace
  );
  const pendingInvitations = user?.suggestedWorkspaces.filter(
    (workspace: IWorkspace) => !isMember(workspace)
  );
  const title = existingWorkspaces?.length
    ? 'Login to your workspaces'
    : 'You have pending invitations';

  return (
    <div className="w-full h-screen overflow-y-auto grid place-items-center scrollbar-hidden">
      <div className="flex flex-col gap-10 md:gap-14 w-full max-w-md my-[50px]">
        <div className="flex flex-col gap-2">
          <h3 className="text-heading-3-small bold text-[var(--text-default)] md:text-heading-3">
            {title}
          </h3>
          {user && (
            <p className="text-body-small regular text-[var(--text-neutral)]">
              You’re signed in as{' '}
              <span className="medium text-[var(--text-default)]">
                {user?.email}
              </span>
            </p>
          )}
        </div>

        {Boolean(existingWorkspaces?.length) && (
          <section className="flex flex-col gap-4">
            <p className="text-heading-6 bold">Existing workspaces</p>
            <ul className="p-4 rounded-xl flex flex-col gap-2 border border-[var(--border-default)] bg-[var(--backgrounds-dropdowns)]">
              {(existingWorkspaces ?? []).map(
                (workspace: IWorkspace, index: number) => (
                  <Fragment>
                    <li
                      key={workspace.id}
                      className="flex justify-between items-center"
                    >
                      <div className="flex items-center space-x-4">
                        <Media
                          src={workspace.logo?.url}
                          width={40}
                          height={40}
                          alt={workspace.name}
                          fontSize={16}
                        />
                        <div className="space-y-0.5">
                          <div className="truncate text-heading-6 medium text-[var(--text-default)]">
                            {workspace.name}
                          </div>
                          <div className="text-body-small regular text-[var(--text-neutral)]">
                            {workspace.members?.length} members
                          </div>
                        </div>
                      </div>
                      <Button
                        type="button"
                        onClick={handleJoinWorkspace(workspace)}
                        className="w-auto"
                        disabled={
                          workspaceStatus.loading &&
                          loadingWorkspaceIDs.includes(workspace.id)
                        }
                        value={'Launch'}
                        size={'medium'}
                        btnType={'neutral'}
                      >
                        {workspaceStatus.loading &&
                          loadingWorkspaceIDs.includes(workspace.id) && (
                            <Spinner />
                          )}
                      </Button>
                    </li>
                    {existingWorkspaces &&
                      index !== existingWorkspaces.length - 1 && (
                        <HorizontalDivider />
                      )}
                  </Fragment>
                )
              )}
            </ul>
          </section>
        )}

        {Boolean(pendingInvitations?.length) && (
          <section className="flex flex-col gap-4">
            {existingWorkspaces?.length && (
              <p className="text-heading-6 bold">Pending Invitations</p>
            )}
            <ul className="p-4 rounded-xl flex flex-col gap-2 border border-[var(--border-default)] bg-[var(--backgrounds-dropdowns)]">
              {(pendingInvitations ?? []).map(
                (workspace: IWorkspace, index: number) => (
                  <Fragment>
                    <li
                      key={workspace.id}
                      className="flex justify-between items-center"
                    >
                      <div className="flex items-center space-x-4">
                        <Media
                          src={workspace.logo?.url}
                          width={40}
                          height={40}
                          alt={workspace.name}
                          fontSize={16}
                        />
                        <div className="space-y-0.5">
                          <div className="truncate text-heading-6 medium text-[var(--text-default)]">
                            {workspace.name}
                          </div>
                          <div className="text-body-small regular text-[var(--text-neutral)]">
                            {workspace.members?.length} members
                          </div>
                        </div>
                      </div>
                      <Button
                        type="button"
                        onClick={handleJoinWorkspace(workspace)}
                        className="w-auto"
                        disabled={
                          workspaceStatus.loading &&
                          loadingWorkspaceIDs.includes(workspace.id)
                        }
                        value={
                          isInvitee(workspace)
                            ? 'Accept Invitation'
                            : 'Join Workspace'
                        }
                        size={'medium'}
                        btnType={'neutral'}
                      >
                        {workspaceStatus.loading &&
                          loadingWorkspaceIDs.includes(workspace.id) && (
                            <Spinner />
                          )}
                      </Button>
                    </li>
                    {pendingInvitations &&
                      index !== pendingInvitations.length - 1 && (
                        <HorizontalDivider />
                      )}
                  </Fragment>
                )
              )}
            </ul>
          </section>
        )}

        <p className="text-body-small medium text-[var(--text-default)] text-center">
          or you might want to
        </p>
        <Link to="/create-workspace">
          <Button
            value={'Create new workspace'}
            size={'large'}
            btnType={'primary'}
            icon="left"
          >
            <Plus />
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default JoinWorkspacePage;
