import { gql } from '@apollo/client';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useCreateOnePostComment } from 'src/api/comment/post-comment.service';
import CustomMentionItem from 'src/components/common/editor/CustomMentionItem';
import ToastMessage from 'src/components/ToastMessage';
import { transformToTitleCase } from 'src/lib/helpers';
import { IComment, IUser } from 'src/types';

interface UseCommentProps {
  postId: string;
  onCommentCreated?: () => void;
  onReplyCreated?: () => void;
  collaborators: IUser[];
  editorPlaceholder?: string;
}

export const useComment = ({
  postId,
  onCommentCreated,
  onReplyCreated,
  editorPlaceholder = '',
  collaborators,
}: UseCommentProps) => {
  const [mentionedUserIDs, setMentionedUserIDs] = useState<string[]>([]);
  const [editorInstance, setEditorInstance] = useState<any>(null);

  const { control, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      comment: '',
      reply: '',
    },
  });

  const [createOnePostComment, { loading: isCreatingComment }] =
    useCreateOnePostComment();

  const handleCreateComment = (
    data: { comment: string; reply: string },
    isReply: boolean = false,
    parentCommentId?: string
  ) => {
    const commentText = isReply ? data.reply : data.comment;
    if (!commentText.length || isCreatingComment) return;

    createOnePostComment({
      variables: {
        data: {
          comment: commentText,
          post: {
            connect: { id: postId },
          },
          mentionedUsers: {
            connect: mentionedUserIDs.map((id) => ({ id })),
          },
          ...(isReply &&
            parentCommentId && {
              parent: {
                connect: { id: parentCommentId },
              },
            }),
        },
      },
      onCompleted: () => {
        if (isReply) {
          onReplyCreated?.();
        } else {
          onCommentCreated?.();
        }
        reset();
      },
      update: (cache, { data }) => {
        if (!data?.createOnePostComment) return;

        const newComment = data.createOnePostComment;
        const commentPostId = newComment.post.id;

        const cachePostId = cache.identify({
          __typename: 'Post',
          id: commentPostId,
        });

        const existingPost = cache.readFragment<{ comments: IComment[] }>({
          id: cachePostId,
          fragment: gql`
            fragment ExistingPostWithComment on Post {
              id
              comments {
                id
                comment
                createdAt
                mentionedUsers {
                  id
                  detail {
                    fullName
                  }
                }
                responses {
                  id
                  comment
                  createdAt
                  mentionedUsers {
                    id
                    detail {
                      fullName
                    }
                  }
                }
              }
            }
          `,
        });

        if (existingPost) {
          const fullNewComment = {
            ...newComment,
            createdAt: new Date().toISOString(),
            responses: [],
            __typename: 'PostComment',
          };

          let updatedComments;

          if (isReply && parentCommentId) {
            updatedComments = existingPost.comments.map((comment) =>
              comment.id === parentCommentId
                ? {
                    ...comment,
                    responses: [...comment.responses, fullNewComment],
                  }
                : comment
            );
          } else {
            updatedComments = [...existingPost.comments, fullNewComment];
          }

          cache.writeFragment({
            id: cachePostId,
            fragment: gql`
              fragment UpdatedPostComments on Post {
                comments
              }
            `,
            data: {
              comments: updatedComments,
            },
          });
        }
      },
      onError: (error) => {
        toast((t) => (
          <ToastMessage id={t.id} title={`Error: ${error.message}`} />
        ));
      },
    });
  };

  const handleEditorChange = (editor: any) => {
    const mentions: string[] = [];
    const model = editor.model;
    const documentRoot = model.document.getRoot();

    if (documentRoot) {
      const range = model.createRangeIn(documentRoot);
      for (const item of range.getItems()) {
        if (item.hasAttribute('mention')) {
          const mention = item.getAttribute('mention');
          if (mention && mention.userId) {
            mentions.push(mention.userId);
          }
        }
      }
    }

    setMentionedUserIDs(mentions);
  };

  const initializeEditor = (editor: any, isReply: boolean = false) => {
    setEditorInstance(editor);

    editor.editing.view.document.on(
      'enter',
      (evt: any, data: any) => {
        const formValues = watch();
        const fieldValue = isReply ? formValues.reply : formValues.comment;

        const isEmpty =
          !fieldValue || fieldValue.replace(/<[^>]*>/g, '').trim() === '';

        if (isEmpty) {
          evt.stop();
          data.preventDefault();
          return;
        }

        if (data.isSoft) {
          return;
        }

        if (!isCreatingComment) {
          evt.stop();
          data.preventDefault();
          handleSubmit((data) => {
            if (isReply) {
              const parentCommentId = editor.parentCommentId;
              handleCreateComment(data, true, parentCommentId);
            } else {
              handleCreateComment(data);
            }
          })();
        }
      },
      { priority: 'high' }
    );

    return editor;
  };

  const handleMentionButtonClick = () => {
    if (!editorInstance) return;

    const selection = editorInstance.model.document.selection;
    const position = selection.getFirstPosition();

    editorInstance.model.change((writer: any) => {
      writer.insertText('@', position);

      const positionAfter = writer.createPositionAfter(position.parent);
      writer.setSelection(positionAfter);
    });

    editorInstance.editing.view.focus();

    setTimeout(() => {
      const mentionPlugin = editorInstance.plugins.get('Mention');
      if (mentionPlugin) {
        mentionPlugin.fire('change:query');
      }
    }, 0);
  };

  const handleEmojiClick = (emoji: string) => {
    if (!editorInstance) return;

    const selection = editorInstance.model.document.selection;
    const position = selection.getFirstPosition();

    editorInstance.model.change((writer: any) => {
      writer.insertText(emoji, position);

      const positionAfter = writer.createPositionAfter(position.parent);
      writer.setSelection(positionAfter);
    });

    editorInstance.editing.view.focus();
  };

  const initializeCommentEditor = (editor: any) => {
    return initializeEditor(editor, false);
  };

  const initializeReplyEditor = (editor: any, parentCommentId: string) => {
    editor.parentCommentId = parentCommentId;
    return initializeEditor(editor, true);
  };

  const editorConfig = {
    placeholder: editorPlaceholder,
    mention: {
      feeds: [
        {
          marker: '@',
          feed: collaborators?.map((collaborator) => ({
            id: `@${collaborator.detail?.fullName ?? collaborator?.email}`,
            userId: collaborator.id,
            name: collaborator.detail?.fullName ?? collaborator?.email,
            position: transformToTitleCase(collaborator.detail?.position),
            avatar: collaborator.detail.avatar,
          })),
          itemRenderer: CustomMentionItem,
          minimumCharacters: 0,
        },
      ],
    },
  };

  return {
    control,
    watch,
    handleSubmit,
    handleCreateComment,
    handleEditorChange,
    isCreatingComment,
    mentionedUserIDs,
    editorConfig,
    handleMentionButtonClick,
    handleEmojiClick,
    initializeCommentEditor,
    initializeReplyEditor,
  };
};
