import { format } from 'date-fns';
import { IconCalendar, WorkStream } from 'src/assets/icons';
import Avatar from 'src/components/ui/Avatar/Avatar';
import ColorTag from 'src/components/ui/tags/ColorTags';
import StatusTag from 'src/components/ui/tags/StatusTags';
import { transformToTitleCase } from 'src/lib/helpers';
import { ITask } from 'src/types/entities';
import { tv } from 'tailwind-variants';
import EmptyAssignee from './EmptyAssignee';

interface TaskCardProps {
  task?: ITask;
  cardType: 'listView' | 'kanbanView';
  onClick?: () => void;
}

const TaskCard = (props: TaskCardProps) => {
  const { task, cardType, onClick } = props;
  return (
    <div className={taskCardStyles({ type: `${cardType}` })} onClick={onClick}>
      <div className={taskCardLeftSlotStyles({ type: `${cardType}` })}>
        {cardType === 'kanbanView' && (
          <ColorTag
            value={task?.workStream[0]?.name || 'Design'}
            size="medium"
            type="filled"
            leftIcon={<WorkStream className="w-3 h-3" />}
          />
        )}
        <div className="text-body-small medium text-[var(--text-default)] truncate max-w-[98%]">
          {task?.title ? transformToTitleCase(task.title) : ''}
        </div>
      </div>

      <div className={taskCardRightSlotStyles({ type: `${cardType}` })}>
        {cardType === 'listView' && (
          <ColorTag
            value={task?.workStream[0]?.name || 'Design'}
            size="medium"
            type="filled"
            leftIcon={<WorkStream className="w-3 h-3" />}
          />
        )}

        <StatusTag
          value={
            task?.dueDate
              ? format(new Date(task.dueDate), 'MMM dd')
              : 'Set due date'
          }
          size="medium"
          type="filled"
          color="default"
          leftIcon={<IconCalendar className="w-3 h-3" />}
        />

        {task?.assignee ? (
          <Avatar
            name={task?.assignee?.detail?.fullName ?? task?.assignee?.email}
            src={task?.assignee?.detail.avatar?.url}
            alt={`${task?.assignee?.detail?.fullName} pfp`}
            size={20}
          />
        ) : (
          <EmptyAssignee />
        )}
      </div>
    </div>
  );
};

const taskCardStyles = tv({
  base: 'w-full grid bg-[var(--backgrounds-on-canvas)] border border-[var(--border-default)] p-2 rounded-[8px] hover:bg-[var(--backgrounds-default)] cursor-pointer',
  variants: {
    type: {
      listView: 'h-[45px] grid-cols-[65%_35%] place-items-center',
      kanbanView: 'h-[110px] items-start grid-cols-1',
    },
  },
  defaultVariants: {
    type: 'listView',
  },
});

const taskCardLeftSlotStyles = tv({
  base: 'flex w-full',
  variants: {
    type: {
      listView: 'gap-x-2 justify-start items-center',
      kanbanView: 'gap-y-2 flex-col items-start',
    },
  },
  defaultVariants: {
    type: 'listView',
  },
});

const taskCardRightSlotStyles = tv({
  base: 'flex items-center gap-x-2 w-full',
  variants: {
    type: {
      listView: 'justify-end',
      kanbanView: 'justify-between',
    },
  },
  defaultVariants: {
    type: 'listView',
  },
});

export default TaskCard;
