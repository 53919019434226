import { Outlet } from 'react-router-dom';
import HomeSideNav from 'src/components/sideNav/HomeSideNav';
import SideNav from 'src/components/sideNav/SideNav';
import WorkspaceMenu from 'src/components/WorkspaceMenu';
import { PostsContextProvider } from 'src/context/post.context';
import { TaskContextProvider } from 'src/context/task.context';

export default function DashboardLayout() {
  return (
    <PostsContextProvider>
      <TaskContextProvider>
        <div className="flex fixed min-h-screen w-full">
          <div className="min-h-screen hidden md:block">
            <SideNav>
              <WorkspaceMenu />
              <HomeSideNav />
            </SideNav>
          </div>
          <div className="w-full h-screen md:h-[calc(100vh-16px)] m-0 overflow-auto flex gap-2 md:m-2">
            <Outlet />
          </div>
        </div>
      </TaskContextProvider>
    </PostsContextProvider>
  );
}
