import { useMutation } from '@apollo/client';
import {
  CREATE_ONE_TASK_COMMENT,
  DELETE_ONE_TASK_COMMENT,
  UPDATE_ONE_TASK_COMMENT,
} from 'src/lib/mutations';

export function useCreateOneTaskComment() {
  return useMutation(CREATE_ONE_TASK_COMMENT);
}

export function useUpdateOneTaskComment() {
  return useMutation(UPDATE_ONE_TASK_COMMENT);
}

export function useDeleteOneTaskComment() {
  return useMutation(DELETE_ONE_TASK_COMMENT);
}
