import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { usePosts, useSummarizeProjectPosts } from 'src/api/post/post.service';
import ToastMessage from 'src/components/ToastMessage';
import { IPost } from 'src/types';

interface PostsContextProps {
  projectId: string | undefined;
  workspaceSlug: string | undefined;
  posts: IPost[] | undefined;
  isLoading: boolean;
  isCatchingUp: boolean;
  catchUpData: any;
  setCatchUpData: (data: any) => void;
  getPostCatchup: () => void;
  refetchPosts: () => void;
}

const PostsContext = createContext<PostsContextProps | undefined>(undefined);

export const PostsContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { projectId, workspaceSlug } = useParams<{
    projectId: string;
    workspaceSlug: string;
  }>();

  const queryVariables = useMemo(
    () => ({
      where: { projectId: { equals: projectId } },
      orderBy: { createdAt: 'desc' as const },
    }),
    [projectId]
  );

  const skipQuery = !projectId;
  const [catchUpData, setCatchUpData] = useState<any>(null);

  const { loading, data, refetch } = usePosts({
    variables: queryVariables,
    skip: skipQuery,
    onError: (error: any) => {
      toast((t) => <ToastMessage id={t.id} title={`${error.message}`} />);
    },
  });

  const [summarizeProjectPosts, { loading: isCatchingUp }] =
    useSummarizeProjectPosts();
  const getPostCatchup = useCallback(() => {
    summarizeProjectPosts({
      variables: {
        where: {
          projectId: { equals: projectId },
        },
        orderBy: { createdAt: 'desc' },
      },
      onCompleted: (data: any) => {
        setCatchUpData(data?.summarizeProjectPosts);
      },
      onError: (error: any) => {
        toast((t) => <ToastMessage id={t.id} title={`${error.message}`} />);
      },
    });
  }, [projectId, summarizeProjectPosts]);

  const refetchPosts = useCallback(() => {
    if (projectId && !skipQuery) {
      refetch(queryVariables);
    }
  }, [projectId, skipQuery, refetch, queryVariables]);

  useEffect(() => {
    if (!skipQuery) {
      refetchPosts();
    }
  }, [refetchPosts, skipQuery]);

  const contextValue = useMemo(
    () => ({
      posts: data?.posts,
      projectId,
      workspaceSlug,
      isLoading: loading,
      isCatchingUp,
      catchUpData,
      setCatchUpData,
      getPostCatchup,
      refetchPosts,
    }),
    [
      data?.posts,
      projectId,
      workspaceSlug,
      loading,
      isCatchingUp,
      catchUpData,
      getPostCatchup,
      refetchPosts,
    ]
  );

  return (
    <PostsContext.Provider value={contextValue}>
      {children}
    </PostsContext.Provider>
  );
};

export const usePostsContext = () => {
  const context = useContext(PostsContext);
  if (!context) {
    throw new Error(
      'usePostsContext must be used within a PostsContextProvider'
    );
  }
  return context;
};
