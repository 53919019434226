import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSendAuthToken } from 'src/api/auth/auth.service';
import { useWorkSpaceByInvitation } from 'src/api/workspace';
import LogoPurple from 'src/components/logo/LogoPurple';
import ToastMessage from 'src/components/ToastMessage';
import Button from 'src/components/ui/Button/Button';
import PageLoader from 'src/components/ui/Loader/PageLoader';
import Media from '../../components/Media';
import { ACCEPT_ONE_WORKSPACE_INVITATION } from '../../lib/mutations';

function AcceptInvitePage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

const {
    loading: workspaceInvitationLoading,
    data: workspaceInvitation,
    error: workspaceInvitationError,
  } = useWorkSpaceByInvitation({
    variables: {
      where: { token: token },
    },
  });

  const [acceptOneWorkspaceInvitation, acceptOneWorkspaceInvitationStatus] =
    useMutation(ACCEPT_ONE_WORKSPACE_INVITATION);
  const [sendAuthToken, sendAuthTokenStatus] = useSendAuthToken();

  const handleAcceptInvite = async () => {
    await acceptOneWorkspaceInvitation({
      variables: {
        where: { token },
      },
      onCompleted: async ({ acceptOneWorkspaceInvitation }) => {
        const { accepted, inviteeEmail, workspace } =
          acceptOneWorkspaceInvitation;

        if (accepted) {
          if (workspace && workspace.slug !== '') {
            await sendAuthToken({ variables: { email: inviteeEmail } });
            navigate(
              `/auth/verify-otp?email=${encodeURIComponent(
                inviteeEmail
              )}&workspaceSlug=${workspace.slug}`
            );
          } else {
            navigate(
              `/auth/verify-otp?email=${encodeURIComponent(inviteeEmail)}`
            );
          }
        }
      },
      onError: (error) => {
        toast((t) => <ToastMessage id={t.id} title={`${error.message}`} />);
      },
    });
  };

  const isAccepting =
    acceptOneWorkspaceInvitationStatus.loading || sendAuthTokenStatus.loading;

  if (workspaceInvitationLoading) return <PageLoader />;

  if (workspaceInvitationError) {
    return (
      <div className="w-full flex flex-col gap-6 items-center justify-center">
        <div>
          <div className="flex items-center gap-1">
            <LogoPurple className="w-[18.655px] h-auto" />
            <h1 className="text-heading font-semibold text-2xl text-[var(--text-default)]">
              overlap
            </h1>
          </div>
        </div>
        <div className="flex p-8 flex-col items-center gap-6 rounded-2xl bg-[var(--backgrounds-on-canvas)]">
          <h1 className="text-heading-5-small md:text-2xl md:leading-8 font-semibold text-center text-[var(--text-default)]">
            Invitation not found
          </h1>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col gap-6 items-center justify-center">
      <div>
        <div className="flex items-center gap-1">
          <LogoPurple className="w-[18.655px] h-auto" />
          <h1 className="text-heading font-semibold text-2xl text-[var(--text-default)]">
            overlap
          </h1>
        </div>
      </div>
      <div className="flex p-8 flex-col items-center gap-6 rounded-2xl bg-[var(--backgrounds-on-canvas)]">
        <Media
          src={workspaceInvitation.workspaceInvitation?.workspace?.logo?.url}
          alt={
            workspaceInvitation.workspaceInvitation?.workspace?.name ||
            'Workspace logo'
          }
          width={40}
          height={40}
          fontSize={16}
        />
        <h1 className="text-heading-5-small md:text-2xl md:leading-8 font-semibold text-center text-[var(--text-default)]">
          {workspaceInvitation.workspaceInvitation?.inviter?.detail?.fullName}{' '}
          has invited you to join{' '}
          {workspaceInvitation.workspaceInvitation?.workspace?.name}’s workspace
        </h1>
        <p className="px-3 py-1 font-normal text-center rounded bg-[var(--backgrounds-default)] text-[var(--text-on-bg)]">
          You’re joining as{' '}
          <span className="font-semibold">
            {workspaceInvitation?.workspaceInvitation?.inviteeEmail}
          </span>
        </p>
        <Button
          value={'Accept invitation'}
          size={'large'}
          btnType={'primary'}
          loading={isAccepting}
          disabled={isAccepting}
          onClick={handleAcceptInvite}
        />
      </div>
    </div>
  );
}

export default AcceptInvitePage;
