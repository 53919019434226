import { useMutation } from '@apollo/client';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import OtpInput from 'react-otp-input';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useLazyMe } from 'src/api/auth/auth.service';
import ToastMessage from 'src/components/ToastMessage';
import BackButton from 'src/components/ui/Button/BackButton';
import Button from 'src/components/ui/Button/Button';
import { useAuth } from 'src/context/auth.context';
import { useSendAuthToken } from '../../api/auth/auth.service';
import { CHECK_AUTH_TOKEN } from '../../lib/mutations';

function VerifyOtpPage() {
  const numInputs = 4;
  const RESEND_INTERVAL = 60;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { login, isAuthenticated } = useAuth();

  const getEmailFromQueryString = (queryString: string): string => {
    const params = new URLSearchParams(queryString);
    const encodedEmail = params.get('email') || '';
    return decodeURIComponent(encodedEmail).replace(/ /g, '+');
  };

  const email = getEmailFromQueryString(location.search);
  const workspaceSlugParam = searchParams.get('workspaceSlug') || '';

  const [token, setToken] = useState('');
  const [resendTimer, setResendTimer] = useState(RESEND_INTERVAL);
  const isValidToken = (token: string) => token.length === numInputs;

  const [getUserData, { loading: isLoadingUser, data: userData }] = useLazyMe();

  const [sendAuthToken, { loading: isSendingToken }] = useSendAuthToken();
  const [checkAuthToken, { loading, error }] = useMutation(CHECK_AUTH_TOKEN);
  const errorMessage = error && `Submission error! ${error.message}`;

  const handleSubmit = useCallback(
    (e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault();

      if (!isValidToken(token)) return;

      checkAuthToken({
        variables: {
          data: { email, token },
        },
        onCompleted: ({ checkAuthToken }) => {
          if (
            checkAuthToken.userId &&
            checkAuthToken.accessToken &&
            checkAuthToken.refreshToken
          ) {
            login({
              accessToken: checkAuthToken.accessToken,
              refreshToken: checkAuthToken.refreshToken,
              userId: checkAuthToken.userId,
            });

            if (!isLoadingUser) {
              if (!userData?.me?.detail) {
                navigate('/');
              } else if (workspaceSlugParam && userData?.me?.detail !== null) {
                navigate(`/workspace/${workspaceSlugParam}/project`);
              } else if (userData?.me?.userWorkspaces.length) {
                const firstWorkspaceSlug =
                  userData.me.userWorkspaces[0]?.workspace?.slug;
                navigate(`/workspace/${firstWorkspaceSlug}/project`);
              } else {
                navigate('/');
              }
            }
          }
        },
        onError: (error) => {
          toast((t) => <ToastMessage id={t.id} title={`${error.message}`} />);
        },
      });
    },
    [
      token,
      checkAuthToken,
      email,
      login,
      isLoadingUser,
      userData,
      workspaceSlugParam,
      navigate,
    ]
  );

  const handleResend = async () => {
    try {
      sendAuthToken({
        variables: { email },
        onCompleted: () => {
          setResendTimer(RESEND_INTERVAL);
        },
      });
    } catch (resendError) {
      console.error('Resend OTP error:', resendError);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getUserData();
    }
  }, [getUserData, isAuthenticated]);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (resendTimer > 0) {
        setResendTimer((prevTimer) => prevTimer - 1);
      }
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, [resendTimer]);

  return (
    <div className="flex flex-col gap-10 md:gap-14">
      <div className="w-full flex flex-col gap-4 items-start max-w-[435px]">
        <BackButton />
        <div className="flex flex-col gap-2 items-start">
          <h3 className="text-heading-3-small bold text-[var(--text-default)] md:text-heading-3">
            Verify OTP
          </h3>
          <p className="text-body-small regular text-[var(--text-neutral)]">
            We just sent a {numInputs}-digit OTP to{' '}
            <span className="medium text-[var(--text-default)]">{email}</span>,
            kindly enter the numbers and continue
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col gap-4 items-start">
        <form
          onSubmit={handleSubmit}
          className="w-full flex flex-col items-center gap-y-6"
        >
          <OtpInput
            value={token}
            onChange={setToken}
            numInputs={numInputs}
            renderInput={(props) => <input {...props} />}
            containerStyle="space-x-4"
            inputStyle="otp-input input w-full flex flex-col items-center justify-center p-4 rounded rounded-lg outline outline-[var(--border-input-default)] bg-[var(--backgrounds-on-canvas)]"
            shouldAutoFocus
          />
          {errorMessage && (
            <p className="text-xs text-red-500 text-center">{errorMessage}</p>
          )}
          <Button
            type="submit"
            btnType="primary"
            size="large"
            value="Verify"
            onClick={handleSubmit}
            loading={loading}
            disabled={loading || isSendingToken || isLoadingUser}
            className="w-full"
          />
        </form>
        <Button
          btnType="ghost"
          size="medium"
          value={
            resendTimer > 0
              ? `Resend ${resendTimer}s`
              : 'Didn’t get the code? Resend'
          }
          loading={isSendingToken}
          disabled={
            resendTimer > 0 || loading || isSendingToken || isLoadingUser
          }
          className="w-full"
          onClick={handleResend}
        />
      </div>
    </div>
  );
}

export default VerifyOtpPage;
