import { gql } from '@apollo/client';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useUpdateOnePostComment } from 'src/api/comment/post-comment.service';
import CustomMentionItem from 'src/components/common/editor/CustomMentionItem';
import ToastMessage from 'src/components/ToastMessage';
import { transformToTitleCase } from 'src/lib/helpers';
import { IComment, IUser } from 'src/types';

interface UseCommentEditProps {
  comment: IComment;
  onEditComplete?: () => void;
  collaborators: IUser[];
}

export const useCommentEdit = ({
  comment,
  onEditComplete,
  collaborators,
}: UseCommentEditProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editorInstance, setEditorInstance] = useState<any>(null);
  const [mentionedUserIDs, setMentionedUserIDs] = useState<string[]>([]);

  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      editedComment: comment.comment,
    },
  });

  const [updateOnePostComment, { loading: isUpdating }] =
    useUpdateOnePostComment();

  const handleEditComment = async (data: { editedComment: string }) => {
    if (data.editedComment === comment.comment || isUpdating) return;

    try {
      await updateOnePostComment({
        variables: {
          data: {
            comment: { set: data.editedComment },
            mentionedUsers: {
              set: mentionedUserIDs.map((id) => ({ id })),
            },
          },
          where: { id: comment.id },
        },
        update: (cache, { data: updateData }) => {
          if (!updateData?.updateOnePostComment) return;

          const updatedComment = updateData.updateOnePostComment;
          const postId = comment.post.id;

          const cacheId = cache.identify({ __typename: 'Post', id: postId });

          const existingPost = cache.readFragment<{ comments: IComment[] }>({
            id: cacheId,
            fragment: gql`
              fragment ExistingPostWithComments on Post {
                id
                comments {
                  id
                  comment
                  createdAt
                  mentionedUsers {
                    id
                    detail {
                      fullName
                    }
                  }
                  responses {
                    id
                    comment
                    createdAt
                    mentionedUsers {
                      id
                      detail {
                        fullName
                      }
                    }
                  }
                }
              }
            `,
          });

          if (existingPost) {
            const updatedComments = existingPost.comments.map((c) => {
              if (c.id === comment.id) {
                return {
                  ...c,
                  ...updatedComment,
                };
              }
              if (c.responses) {
                const updatedResponses = c.responses.map((r) =>
                  r.id === comment.id ? { ...r, ...updatedComment } : r
                );
                return { ...c, responses: updatedResponses };
              }
              return c;
            });

            cache.writeFragment({
              id: cacheId,
              fragment: gql`
                fragment UpdatedPostComments on Post {
                  comments
                }
              `,
              data: {
                comments: updatedComments,
              },
            });
          }
        },
      });

      setIsEditing(false);
      onEditComplete?.();
    } catch (error: any) {
      toast((t) => <ToastMessage id={t.id} title={error.message} />);
    }
  };

  const handleEditorChange = useCallback((editor: any) => {
    const mentions: string[] = [];
    const model = editor.model;
    const documentRoot = model.document.getRoot();

    if (documentRoot) {
      const range = model.createRangeIn(documentRoot);
      for (const item of range.getItems()) {
        if (item.hasAttribute('mention')) {
          const mention = item.getAttribute('mention');
          if (mention && mention.userId) {
            mentions.push(mention.userId);
          }
        }
      }
    }

    setMentionedUserIDs(mentions);
  }, []);

  const initializeEditor = (editor: any) => {
    setEditorInstance(editor);

    editor.editing.view.document.on(
      'enter',
      (evt: any, data: any) => {
        const formValues = watch();
        const isEmpty =
          !formValues.editedComment ||
          formValues.editedComment.replace(/<[^>]*>/g, '').trim() === '';

        if (isEmpty) {
          evt.stop();
          data.preventDefault();
          return;
        }

        if (data.isSoft) {
          return;
        }

        if (!isUpdating && formValues.editedComment !== comment.comment) {
          evt.stop();
          data.preventDefault();
          handleSubmit(handleEditComment)();
        }
      },
      { priority: 'high' }
    );

    return editor;
  };

  const handleMentionButtonClick = () => {
    if (!editorInstance) return;

    const selection = editorInstance.model.document.selection;
    const position = selection.getFirstPosition();

    editorInstance.model.change((writer: any) => {
      writer.insertText('@', position);

      const positionAfter = writer.createPositionAfter(position.parent);
      writer.setSelection(positionAfter);
    });

    editorInstance.editing.view.focus();

    setTimeout(() => {
      const mentionPlugin = editorInstance.plugins.get('Mention');
      if (mentionPlugin) {
        mentionPlugin.fire('change:query');
      }
    }, 0);
  };

  const handleEmojiClick = (emoji: string) => {
    if (!editorInstance) return;

    const selection = editorInstance.model.document.selection;
    const position = selection.getFirstPosition();

    editorInstance.model.change((writer: any) => {
      writer.insertText(emoji, position);

      const positionAfter = writer.createPositionAfter(position.parent);
      writer.setSelection(positionAfter);
    });

    editorInstance.editing.view.focus();
  };

  const startEditing = () => {
    setIsEditing(true);
  };

  const cancelEditing = () => {
    setIsEditing(false);
    setValue('editedComment', comment.comment);
    setMentionedUserIDs([]);
  };

  const editorConfig = {
    mention: {
      feeds: [
        {
          marker: '@',
          feed: collaborators?.map((collaborator) => ({
            id: `@${collaborator.detail?.fullName ?? collaborator?.email}`,
            userId: collaborator.id,
            name: collaborator.detail?.fullName ?? collaborator?.email,
            position: transformToTitleCase(collaborator.detail?.position),
            avatar: collaborator.detail.avatar,
          })),
          itemRenderer: CustomMentionItem,
          minimumCharacters: 0,
        },
      ],
    },
  };

  return {
    isEditing,
    startEditing,
    cancelEditing,
    control,
    handleSubmit,
    handleEditComment,
    handleEditorChange,
    isUpdating,
    editedComment: watch('editedComment'),
    mentionedUserIDs,
    editorConfig,
    handleMentionButtonClick,
    handleEmojiClick,
    initializeEditor,
  };
};
