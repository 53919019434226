import { CKEditor } from '@ckeditor/ckeditor5-react';
import BalloonEditor from 'ckeditor5-custom-build';
import { format } from 'date-fns';
import { motion } from 'framer-motion';
import { memo, useCallback, useEffect, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useUpdateOneTask } from 'src/api/task/task.service';
import {
  ChevronDown,
  Circle,
  IconCalendar,
  IconChevronLeft,
  Status,
  UserCircle,
  WorkStream,
} from 'src/assets/icons';
import ToastMessage from 'src/components/ToastMessage';
import AssignItem from 'src/components/ui/AssignItem';
import Avatar from 'src/components/ui/Avatar/Avatar';
import IconButton from 'src/components/ui/Button/IconButton';
import ColorTag from 'src/components/ui/tags/ColorTags';
import StatusTag from 'src/components/ui/tags/StatusTags';
import { useTaskContext } from 'src/context/task.context';
import { useUser } from 'src/context/user.context';
import { getTaskStatusColor, transformToTitleCase } from 'src/lib/helpers';
import { IProjectTopic } from 'src/lib/types';
import { cn } from 'src/lib/utils';
import { ITask, IUser, TaskStatus, TTaskStatus } from 'src/types';
import CustomSelect from '../CustomSelect';
import DatePickerPopover from '../DatePickerPopover';
import { StatusLabel } from '../tasks/TaskBoard';
import TaskActionsDialog from './TaskActionsDialog';
import TaskCommentSection from './TaskCommentSection';

interface TaskDetailsFlyoutProps {
  taskDetails: ITask | null;
  openTaskModal: boolean;
  closeTaskModal: () => void;
  workStreams: IProjectTopic[] | null;
  collaborators: IUser[];
  userCollaborates: boolean;
}

interface ITaskFormData {
  title: string;
  description: string;
  status: TTaskStatus;
  dueDate: string;
  assignee: string | null;
  workStream: string | null;
}

const TaskDetailFlyout = (props: TaskDetailsFlyoutProps) => {
  const {
    taskDetails: initialTaskDetails,
    openTaskModal,
    closeTaskModal,
    workStreams,
    collaborators,
    userCollaborates,
  } = props;

  const { workspaceSlug, projectId } = useParams<{
    workspaceSlug: string;
    projectId: string;
  }>();

  const { user } = useUser();
  const { openDeleteConfirmation } = useTaskContext();

  const [openSelects, setOpenSelects] = useState<Set<string>>(new Set());
  const [truncateTitle, setTruncateTitle] = useState(true);

  const [updateOneTask] = useUpdateOneTask();

  const { control, reset, watch } = useForm<ITaskFormData>({
    defaultValues: {
      title: initialTaskDetails?.title || '',
      description: initialTaskDetails?.description || '',
      status: initialTaskDetails?.status || TaskStatus.NOT_STARTED,
      dueDate: initialTaskDetails?.dueDate || new Date().toISOString(),
      assignee: initialTaskDetails?.assignee?.id || null,
      workStream: initialTaskDetails?.workStream?.[0]?.id || null,
    },
  });

  const canEditCheck = useCallback(() => {
    return (
      user?.id === initialTaskDetails?.assignee?.id ||
      user?.id === initialTaskDetails?.createdBy?.id
    );
  }, [user, initialTaskDetails]);

  const canEditTask = canEditCheck();

  //coming back to this
  const handleSelectOpenChange = useCallback((id: string, isOpen: boolean) => {
    setOpenSelects((prev) => {
      const newSet = new Set(prev);
      if (isOpen) {
        newSet.add(id);
      } else {
        newSet.delete(id);
      }
      return newSet;
    });
  }, []);

  const handleCopyTaskLink = async () => {
    const origin = window.location.origin;
    const BASE_URL = `${origin}/workspace/${workspaceSlug}/project/${projectId}`;
    const TASK_LINK = `${BASE_URL}/tasks?taskId=${initialTaskDetails?.id}`;

    await navigator.clipboard
      .writeText(TASK_LINK)
      .then(() => {
        handleSelectOpenChange('actions', false);
        toast(
          (t) => (
            <ToastMessage
              id={t.id}
              title={'Link to task is copied to clipboard'}
            />
          ),
          { duration: 5000 }
        );
      })
      .catch((err) => {
        toast((t) => (
          <ToastMessage id={t.id} title={'Error copying link to clipboard'} />
        ));
      });
  };

  const updateTask = useCallback(
    async (data: Partial<ITaskFormData>) => {
      if (!initialTaskDetails?.id) return;

      try {
        const updateData: any = {};

        Object.entries(data).forEach(([key, value]) => {
          if (value !== undefined) {
            if (key === 'assignee') {
              updateData[key] = value
                ? { connect: { id: value } }
                : { disconnect: true };
            } else if (key === 'workStream') {
              updateData[key] = { set: value ? [{ id: value }] : [] };
            } else {
              updateData[key] = { set: value };
            }
          }
        });

        await updateOneTask({
          variables: {
            data: updateData,
            where: {
              id: initialTaskDetails.id,
              projectId: { equals: projectId },
            },
          },
          onCompleted: () => {
            toast((t) => (
              <ToastMessage id={t.id} title={'Task updated successfully'} />
            ));
          },
        });
      } catch (error: any) {
        console.error('Error updating task:', error);
        toast((t) => (
          <ToastMessage
            id={t.id}
            title={`Error updating task: ${error.message}`}
          />
        ));
      }
    },
    [initialTaskDetails, projectId, updateOneTask]
  );

  const handleTitleBlur = useCallback(
    (event: React.FocusEvent<HTMLElement>) => {
      setTruncateTitle(true);

      const newTitle = event.target.innerText;
      if (newTitle !== initialTaskDetails?.title) {
        updateTask({ title: newTitle });
      }
    },
    [initialTaskDetails, updateTask]
  );

  const handleDescriptionBlur = useCallback(
    (event: any, editor: any) => {
      const newDescription = editor.getData();
      if (newDescription !== initialTaskDetails?.description) {
        updateTask({ description: newDescription });
      }
    },
    [initialTaskDetails, updateTask]
  );

  useEffect(() => {
    if (initialTaskDetails) {
      reset({
        title: initialTaskDetails.title,
        description: initialTaskDetails.description,
        status: initialTaskDetails.status,
        dueDate: initialTaskDetails.dueDate,
        assignee: initialTaskDetails.assignee?.id || null,
        workStream: initialTaskDetails.workStream?.[0]?.id || null,
      });
    }
  }, [initialTaskDetails, reset]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (
        name &&
        ['status', 'dueDate', 'assignee', 'workStream'].includes(name)
      ) {
        updateTask({ [name]: value[name as keyof ITaskFormData] });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, updateTask]);

  useEffect(() => {
    if (openTaskModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [openTaskModal]);

  if (!initialTaskDetails) return null;

  return (
    <motion.div className="fixed z-50 inset-0 grid place-items-center overflow-hidden">
      <motion.div
        className="fixed inset-0 z-10 bg-[var(--backgrounds-blanket)]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.5 }}
        exit={{ opacity: 0 }}
        onClick={closeTaskModal}
      />
      <motion.div
        // ref={taskDetailsRef}
        onClick={(e) => e.stopPropagation()}
        initial={{ x: 600, opacity: 0.8 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 600, opacity: 0.8 }}
        transition={{ type: 'spring', stiffness: 200, damping: 30 }}
        className="z-40 w-full max-w-[600px] h-[calc(100vh-16px)] right-2 absolute border border-[var(--border-default)] bg-[var(--backgrounds-on-canvas)] shadow-lg rounded-lg !overflow-hidden"
      >
        <div className="w-full px-5 pt-5 relative h-full flex items-center flex-col pb-[92px]">
          <div className="flex items-center flex-col gap-y-6 w-full pb-4 sticky top-0 z-5 border-b border-[var(--border-default)]">
            <div className="w-full flex items-center justify-between">
              <IconButton
                icon={<IconChevronLeft />}
                size={'micro'}
                type={'ghost'}
                onClick={closeTaskModal}
              />
              <TaskActionsDialog
                open={openSelects.has('actions')}
                onOpenChange={(isOpen) =>
                  handleSelectOpenChange('actions', isOpen)
                }
                onCopyAction={handleCopyTaskLink}
                canDelete={canEditTask}
                onDeleteAction={() =>
                  openDeleteConfirmation(initialTaskDetails)
                }
              />
            </div>
            <div className="w-full flex flex-col items-start gap-y-6">
              <div className="w-full">
                <Controller
                  name="title"
                  control={control}
                  disabled={!userCollaborates}
                  render={({ field }) => (
                    <ContentEditable
                      {...field}
                      data-placeholder="Task title"
                      html={field.value || ''}
                      onChange={(e) => field.onChange(e.target.value)}
                      onBlur={handleTitleBlur}
                      onFocus={(e) => {
                        setTruncateTitle(false);
                      }}
                      tagName="h3"
                      className={cn(
                        'bold text-heading-3 text-[var(--text-default)] w-full bg-transparent border-none outline-none focus:outline-none',
                        truncateTitle
                          ? 'truncate max-w-[100%]'
                          : 'truncate-none'
                      )}
                    />
                  )}
                />
              </div>

              <div className="w-full flex flex-col items-start gap-y-4">
                <div className="w-full grid grid-cols-[133px_1fr]">
                  <AssignItem
                    icon={<UserCircle className="!w-[14px] !h-[14px]" />}
                    text={'Assignee'}
                  />
                  <div className="!w-[fit-content] flex items-center">
                    <Controller
                      name="assignee"
                      control={control}
                      disabled={!userCollaborates}
                      render={({ field }) => (
                        <CustomSelect
                          {...field}
                          triggerClassName="disabled:opacity-1"
                          value={field.value ?? undefined}
                          onChange={field.onChange}
                          customValueItem={(value) => (
                            <div className="flex gap-1.5 items-center">
                              <Avatar
                                name={(value?.label as string) || ''}
                                size={20}
                                src={value?.others?.detail?.avatar?.url || ''}
                              />
                              <p className="text-body-micro medium text-[var(--text-default)] max-w-[150px] truncate">
                                {value?.others?.detail?.fullName ??
                                  value?.others?.email}
                              </p>
                            </div>
                          )}
                          options={
                            collaborators?.map((collaborator) => ({
                              label:
                                collaborator?.detail?.fullName ??
                                collaborator?.email,
                              value: collaborator?.id,
                              others: collaborator,
                            })) || []
                          }
                          onOpenChange={(isOpen) =>
                            handleSelectOpenChange('assignee', isOpen)
                          }
                          placeholder={'Select assignee'}
                          searchPlaceholder="Search assignee"
                          contentClassName="w-[250px]"
                          customOptionItem={(option) => (
                            <div className="flex items-center gap-x-2 py-[2.5px]">
                              <Avatar
                                name={option?.label as string}
                                size={28}
                              />
                              <div className="flex-1">
                                <div className="text-body-small medium text-[var(--text-default)] max-w-[150px] truncate">
                                  {option?.label}
                                </div>
                                <div className="text-body-micro regular text-[var(--text-neutral)]">
                                  {transformToTitleCase(
                                    option.others?.detail?.position
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="w-full grid grid-cols-[133px_1fr]">
                  <AssignItem
                    icon={<WorkStream className="!w-[14px] !h-[14px]" />}
                    text={'Workstream'}
                  />
                  <div className="!w-[fit-content] flex items-center">
                    <Controller
                      name="workStream"
                      control={control}
                      disabled={!userCollaborates}
                      render={({ field }) => (
                        <CustomSelect
                          {...field}
                          triggerClassName="disabled:opacity-1"
                          value={field.value ?? undefined}
                          onChange={field.onChange}
                          options={
                            workStreams?.map((workstream: any) => ({
                              label: workstream.name,
                              value: workstream.id,
                            })) || []
                          }
                          onOpenChange={(isOpen) =>
                            handleSelectOpenChange('workstream', isOpen)
                          }
                          placeholder={'Select workstream'}
                          searchPlaceholder="Search workstream"
                          contentClassName="w-[200px]"
                          customValueItem={(option) => (
                            <ColorTag
                              value={option.label}
                              size="medium"
                              type="filled"
                              leftIcon={<WorkStream className="w-3 h-3" />}
                            />
                          )}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="w-full grid grid-cols-[133px_1fr]">
                  <AssignItem
                    icon={<Status className="!w-[14px] !h-[14px]" />}
                    text={'Status'}
                  />
                  <div className="!w-[fit-content] flex items-center">
                    <Controller
                      name="status"
                      control={control}
                      disabled={!userCollaborates}
                      render={({ field }) => (
                        <CustomSelect
                          {...field}
                          triggerClassName="disabled:opacity-1"
                          options={Object.values(TaskStatus).map((status) => ({
                            value: status,
                            label: status,
                          }))}
                          onOpenChange={(isOpen) =>
                            handleSelectOpenChange('status', isOpen)
                          }
                          placeholder={'Select status'}
                          renderSearch={false}
                          contentClassName="w-[200px]"
                          customValueItem={(option) => (
                            <StatusTag
                              leftIcon={<Circle />}
                              color={
                                getTaskStatusColor[
                                  option.value as keyof typeof TaskStatus
                                ] || 'default'
                              }
                              value={
                                StatusLabel[
                                  option.label as keyof typeof StatusLabel
                                ]
                              }
                              size="medium"
                            />
                          )}
                          customOptionItem={(option) => (
                            <div className="flex gap-1.5 items-center">
                              <span
                                className={`dot dot-${
                                  getTaskStatusColor[
                                    option.value as keyof typeof TaskStatus
                                  ]
                                }`}
                              ></span>
                              <p className="text-body-small medium text-[var(--text-neutral)]">
                                {transformToTitleCase(option.label)}
                              </p>
                            </div>
                          )}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="w-full grid grid-cols-[133px_1fr]">
                  <AssignItem
                    icon={<IconCalendar className="!w-[14px] !h-[14px]" />}
                    text={'Due date'}
                  />
                  <div className="!w-[fit-content] flex items-center">
                    <Controller
                      name="dueDate"
                      control={control}
                      disabled={!userCollaborates}
                      render={({ field }) => (
                        <DatePickerPopover
                          {...field}
                          triggerClassName="disabled:opacity-1"
                          value={field.value}
                          onChange={(date) =>
                            field.onChange(new Date(date).toISOString())
                          }
                          onOpenChange={(isOpen) =>
                            handleSelectOpenChange('dueDate', isOpen)
                          }
                        >
                          <StatusTag
                            leftIcon={<IconCalendar className="w-3 h-3" />}
                            rightIcon={<ChevronDown className="w-3 h-3" />}
                            color={'default'}
                            size={'medium'}
                            value={format(
                              new Date(field.value),
                              'MMM dd, yyyy'
                            )}
                          />
                        </DatePickerPopover>
                      )}
                    />
                  </div>
                </div>

                <div className="w-full">
                  <Controller
                    name="description"
                    control={control}
                    disabled={!userCollaborates}
                    render={({ field }) => (
                      <CKEditor
                        {...field}
                        editor={BalloonEditor}
                        data={field.value}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          field.onChange(data);
                        }}
                        onBlur={handleDescriptionBlur}
                        config={{
                          placeholder: 'Type a description',
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>

          <TaskCommentSection
            myData={user}
            collaborators={collaborators}
            userCollaborates={userCollaborates}
            comments={initialTaskDetails.comments}
          />
        </div>
      </motion.div>
    </motion.div>
  );
};

export default memo(TaskDetailFlyout);
