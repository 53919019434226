import { useMutation } from '@apollo/client';

import {
  CREATE_ONE_USER_WORKSPACE,
  CREATE_ONE_WORKSPACE_DOMAIN,
  DEACTIVATE_ONE_WORKSPACE_MEMBER,
  DELETE_ONE_WORKSPACE,
  DELETE_ONE_WORKSPACE_INVITATION,
  DISABLE_ONE_WORKSPACE_DOMAIN,
  ENABLE_ONE_WORKSPACE_DOMAIN,
  INVITE_TEAM_TO_ONE_USER_WORKSPACE,
  INVITE_TEAM_TO_ONE_WORKSPACE,
  JOIN_ONE_WORKSPACE,
  REACTIVATE_ONE_WORKSPACE_MEMBER,
  REMOVE_ONE_WORKSPACE_DOMAIN,
  SEND_ONE_WORKSPACE_DOMAIN_TOKEN,
  UPDATE_ONE_WORKSPACE,
  UPDATE_ONE_WORKSPACE_INVITATION,
  UPDATE_ONE_WORKSPACE_LOGO,
  UPDATE_ONE_WORKSPACE_MEMBER_ROLE,
  VERIFY_DELETE_ONE_WORKSPACE,
  VERIFY_ONE_WORKSPACE_DOMAIN,
} from 'src/lib/mutations';

import { ME, WORKSPACE, WORKSPACES } from 'src/lib/queries';

export function useVerifyOneWorkspaceDomain() {
  return useMutation(VERIFY_ONE_WORKSPACE_DOMAIN, {
    refetchQueries: [WORKSPACE],
  });
}

export function useVerifyDeleteOneWorkspace() {
  return useMutation(VERIFY_DELETE_ONE_WORKSPACE, {
    refetchQueries: [ME],
  });
}

export function useUpdateOneWorkspaceMemberRole() {
  return useMutation(UPDATE_ONE_WORKSPACE_MEMBER_ROLE, {
    refetchQueries: [WORKSPACE],
  });
}

export function useUpdateOneWorkspaceLogo() {
  return useMutation(UPDATE_ONE_WORKSPACE_LOGO, {
    refetchQueries: [{ query: ME }],
  });
}

export function useUpdateOneWorkspaceInvitation() {
  return useMutation(UPDATE_ONE_WORKSPACE_INVITATION, {
    refetchQueries: [WORKSPACE],
  });
}

export function useCreateOneUserWorkspace() {
  return useMutation(CREATE_ONE_USER_WORKSPACE, {
    refetchQueries: [ME, WORKSPACES, WORKSPACE],
  });
}

export function useUpdateOneWorkspace() {
  return useMutation(UPDATE_ONE_WORKSPACE, {
    refetchQueries: [WORKSPACE],
  });
}

export function useSendOneWorkspaceDomainToken() {
  return useMutation(SEND_ONE_WORKSPACE_DOMAIN_TOKEN);
}

export function useRemoveOneWorkspaceDomain() {
  return useMutation(REMOVE_ONE_WORKSPACE_DOMAIN, {
    refetchQueries: [WORKSPACE],
  });
}

export function useReactivateOneWorkspaceMember() {
  return useMutation(REACTIVATE_ONE_WORKSPACE_MEMBER, {
    refetchQueries: [WORKSPACE],
  });
}

export function useJoinOneWorkspace() {
  return useMutation(JOIN_ONE_WORKSPACE, {
    refetchQueries: [ME],
  });
}

export function useEnableOneWorkspaceDomain() {
  return useMutation(ENABLE_ONE_WORKSPACE_DOMAIN);
}

export function useDisableOneWorkspaceDomain() {
  return useMutation(DISABLE_ONE_WORKSPACE_DOMAIN, {
    refetchQueries: [WORKSPACE],
  });
}

export function useDeleteOneWorkspaceInvitation() {
  return useMutation(DELETE_ONE_WORKSPACE_INVITATION);
}

export function useDeleteOneWorkspace() {
  return useMutation(DELETE_ONE_WORKSPACE, {
    refetchQueries: [ME, WORKSPACES],
  });
}

export function useDeactivateOneWorkspaceMember() {
  return useMutation(DEACTIVATE_ONE_WORKSPACE_MEMBER, {
    refetchQueries: [WORKSPACE, ME],
  });
}

export function useCreateOneWorkspaceDomain() {
  return useMutation(CREATE_ONE_WORKSPACE_DOMAIN);
}

export function useInviteTeamToOneUserWorkspace() {
  return useMutation(INVITE_TEAM_TO_ONE_USER_WORKSPACE);
}

export function useInviteTeamToOneWorkspace() {
  return useMutation(INVITE_TEAM_TO_ONE_WORKSPACE, {
    refetchQueries: [WORKSPACE],
  });
}
