import clsx from 'clsx';
import React from 'react';
import { tv } from 'tailwind-variants';
import Spinner from '../spinner/circle-spinner/Spinner';

export default function IconButton({
  open,
  icon,
  size,
  type,
  rounded,
  outline,
  className,
  disabled,
  onClick,
  loading = false,
}: {
  open?: boolean;
  icon: React.ReactNode;
  size: 'micro' | 'small' | 'standard' | 'large';
  type: 'ghost' | 'neutral' | 'primary';
  rounded?: 'micro' | 'small' | 'standard' | 'large' | 'full';
  outline?: 'ghost';
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  loading?: boolean;
}) {
  return (
    <button
      disabled={disabled}
      className={`${clsx(
        'icon_button rounded-lg flex items-center justify-center aspect-square transition-all duration-200 overflow-hidden relative disabled:cursor-not-allowed disabled:pointer-events-none disabled:opacity-50',
        size,
        type,
        className,
        {
          open: open,
        }
      )}
      ${iconButtonStyles({ rounded: rounded, outline: outline })}
      `}
      type="button"
      onClick={onClick}
    >
      {loading && (
        <div className="absolute inset-0 bg-[inherit] grid place-items-center">
          <Spinner />
        </div>
      )}
      <div className="transition-all duration-200">{icon}</div>
    </button>
  );
}

const iconButtonStyles = tv({
  variants: {
    rounded: {
      micro: '!rounded',
      small: '!rounded',
      standard: '!rounded-lg',
      large: '!rounded-lg',
      full: '!rounded-full',
    },
    outline: {
      ghost:
        '!bg-transparent outline-1 outline !ring-[var(--border-on-bg)] ring-offset-transparent',
    },
  },
  defaultVariants: {
    rounded: 'small',
  },
});
