import { useEffect, useState } from 'react';
import { Copy, Edit, ThreeDots, Trash } from 'src/assets/icons';
import { ReactComponent as AiLogo } from 'src/assets/svgs/ImgAISummary.svg';
import IconButton from 'src/components/ui/Button/IconButton';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'src/components/ui/DropdownMenu';
import { cn } from 'src/lib/utils';

interface IPostMenuProps {
  showMenu?: boolean;
  setShowMenu?: (value: boolean) => void;
  handleCopy?: () => void;
  handleEdit?: () => void;
  handleDelete?: () => void;
  handleSummarize?: () => void;
  canEdit?: boolean;
  canDelete?: boolean;
  type?: 'post' | 'comment' | 'reply';
}

const PostMenu = (props: IPostMenuProps) => {
  const {
    showMenu = true,
    setShowMenu,
    handleCopy,
    handleEdit,
    handleDelete,
    handleSummarize,
    canDelete = false,
    canEdit = false,
    type = 'post',
  } = props;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open && !showMenu) {
      setShowMenu?.(true);
    }
  }, [open, setShowMenu, showMenu]);

  return (
    <DropdownMenu
      onOpenChange={(isOpen) => {
        setOpen(isOpen);
      }}
      // modal={false}
    >
      <DropdownMenuTrigger
        className={cn(
          'w-fit',
          showMenu ? 'opacity-1 visible' : 'opacity-0 invisible'
        )}
      >
        <IconButton
          open={open}
          size="micro"
          type="ghost"
          rounded="small"
          icon={<ThreeDots />}
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {handleSummarize && (
          <DropdownMenuItem onClick={handleSummarize}>
            <AiLogo />
            <p className="title bg-gradient-summary inline-block !text-transparent !bg-clip-text">
              Summarize
            </p>
          </DropdownMenuItem>
        )}

        {canEdit && (
          <DropdownMenuItem onClick={handleEdit}>
            <Edit />
            <p className="text-inherit">Edit {type}</p>
          </DropdownMenuItem>
        )}

        <DropdownMenuItem onClick={handleCopy}>
          <Copy />
          <p className="text-inherit">Copy Link</p>
        </DropdownMenuItem>

        {canDelete && (
          <DropdownMenuItem onClick={handleDelete}>
            <Trash />
            <p className="text-inherit">Delete {type}</p>
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default PostMenu;
