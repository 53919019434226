import { useLocalStorage } from '@uidotdev/usehooks';
import { differenceInDays } from 'date-fns';
import { Fragment, useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import {
  useArchivedProjects,
  useRestoreOneProject,
} from 'src/api/project/project.service';
import { Circle, Lock } from 'src/assets/icons';
import ToastMessage from 'src/components/ToastMessage';
import Avatar from 'src/components/ui/Avatar/Avatar';
import Badge from 'src/components/ui/Badge';
import Button from 'src/components/ui/Button/Button';
import HeaderText from 'src/components/ui/HeaderTexts';
import HorizontalDivider from 'src/components/ui/HorizontalDivider';
import Tabs from 'src/components/ui/tables/Tab';
import StatusTag from 'src/components/ui/tags/StatusTags';
import { useWorkspaceContext } from 'src/context/workspace.context';
import { colorStyles } from 'src/lib/colorPreset';
import {
  getColorTag,
  getProjectStatusColor,
  transformToTitleCase,
} from 'src/lib/helpers';
import { IProject } from 'src/types';
import { tv } from 'tailwind-variants';

const tabs = [
  { id: 'ACTIVE', value: 'Active' },
  { id: 'ARCHIVED', value: 'Archived' },
  { id: 'DELETED', value: 'Deleted' },
];

interface ModIProject extends IProject {
  type: 'ACTIVE' | 'ARCHIVED' | 'DELETED';
}

type LoadingState = {
  [id: string]: boolean;
};

export default function ProjectSettingsPage() {
  const { workspace } = useWorkspaceContext();

  const [userId] = useLocalStorage('userId', undefined);
  const [currentTab, setCurrentTab] = useState(tabs[0].id);
  const [loadingRestore, setLoadingRestore] = useState<LoadingState>({});

  const [restoreOneProject] = useRestoreOneProject();
  const { data: workspaceProjects, loading: isLoading } = useArchivedProjects({
    variables: {
      where: { workspaceId: { equals: workspace?.id } },
      orderBy: { createdAt: 'desc' as const },
    },
    skip: !workspace?.id,
  });

  const modifiedProjectsData = (workspaceProjects?.archivedProjects ?? []).map(
    (project: ModIProject) => ({
      ...project,
      type: project.deleteAt
        ? 'DELETED'
        : project.archivedAt
        ? 'ARCHIVED'
        : 'ACTIVE',
    })
  );

  const projectCounts = {
    ACTIVE: modifiedProjectsData.filter(
      (project: ModIProject) => project.type === 'ACTIVE'
    ).length,
    ARCHIVED: modifiedProjectsData.filter(
      (project: ModIProject) => project.type === 'ARCHIVED'
    ).length,
    DELETED: modifiedProjectsData.filter(
      (project: ModIProject) => project.type === 'DELETED'
    ).length,
  };

  const getProjectCountMessage = (tab: string, count: number) => {
    if (count === 0) {
      switch (tab) {
        case 'ACTIVE':
          return 'No active projects';
        case 'ARCHIVED':
          return 'No archived projects';
        case 'DELETED':
          return 'No deleted projects';
        default:
          return 'No projects';
      }
    }

    return `${count} ${tab.charAt(0) + tab.slice(1).toLowerCase()} ${
      count === 1 ? 'Project' : 'Projects'
    }`;
  };

  const filteredProjects = modifiedProjectsData.filter(
    (project: ModIProject) => {
      return project.type === currentTab;
    }
  );

  const handleRestoreProject = useCallback(
    async (projectId: string) => {
      try {
        setLoadingRestore((prev) => ({ ...prev, [projectId]: true }));

        await restoreOneProject({
          variables: { where: { id: projectId } },
          onCompleted: (data) => {
            toast((t) => (
              <ToastMessage id={t.id} title="Project is now restored" />
            ));
          },
          update: (cache, { data }) => {
            const restoredProject = data?.restoreOneProject;
            cache.modify({
              fields: {
                archivedProjects(existingProjects = [], { readField }) {
                  return existingProjects.filter(
                    (postRef: { __ref: string }) =>
                      restoredProject.id !== readField('id', postRef)
                  );
                },
              },
            });
          },
        });
      } catch (error) {
        toast((t) => (
          <ToastMessage
            id={t.id}
            title="There was an error restoring the project"
          />
        ));
      } finally {
        setLoadingRestore((prev) => ({ ...prev, [projectId]: false }));
      }
    },
    [restoreOneProject]
  );

  return (
    <div className="workspace-page flex flex-col gap-10">
      <div className="header_div">
        <HeaderText title="Projects" />
        <HorizontalDivider />
      </div>

      <section className="flex flex-col items-start gap-6">
        <HeaderText
          subTitle="Manage your workspace’s projects"
          snippet="Restore archived or deleted projects"
        />

        <div className="w-full flex flex-col items-start gap-6">
          <Tabs
            tabs={tabs}
            onTabChange={setCurrentTab}
            initialSelectedTab={currentTab}
          />
          <p className="text-body-small medium text-[var(--text-default)]">
            {getProjectCountMessage(
              currentTab,
              projectCounts[currentTab as keyof typeof projectCounts]
            )}
          </p>

          {currentTab === 'DELETED' && (
            <div className="bg-[var(--backgrounds-warning)] border-[var(--border-default)] rounded-[8px] w-full flex items-center justify-start gap-x-2 px-4 h-10">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.00011 1.00006C2.46968 1.00006 1.96097 1.21077 1.5859 1.58584C1.21083 1.96091 1.00012 2.46962 1.00012 3.00005V13C1.00012 13.5304 1.21083 14.0391 1.5859 14.4142C1.96097 14.7892 2.46968 15 3.00011 15H13C13.5305 15 14.0392 14.7892 14.4142 14.4142C14.7893 14.0391 15 13.5304 15 13V3.00005C15 2.46962 14.7893 1.96091 14.4142 1.58584C14.0392 1.21077 13.5305 1.00006 13 1.00006H3.00011ZM6.91408 4.00004H8.65206L8.50006 9.60199H7.07008L6.91408 4.00004ZM8.72306 11.164C8.71995 11.4118 8.62013 11.6486 8.44489 11.8238C8.26965 11.999 8.03287 12.0989 7.78507 12.102C7.65991 12.1061 7.53521 12.085 7.41837 12.0399C7.30154 11.9948 7.19496 11.9267 7.10497 11.8396C7.01499 11.7525 6.94344 11.6483 6.89457 11.533C6.8457 11.4177 6.82052 11.2937 6.82052 11.1685C6.82052 11.0433 6.8457 10.9193 6.89457 10.804C6.94344 10.6887 7.01499 10.5844 7.10497 10.4973C7.19496 10.4102 7.30154 10.3421 7.41837 10.2971C7.53521 10.252 7.65991 10.2309 7.78507 10.235C8.28507 10.235 8.71906 10.652 8.72306 11.164Z"
                  fill="var(--text-warning)"
                />
              </svg>
              <p className="text-body-small medium text-[var(--text-warning)]">
                Deleted projects can be restored within 30 days, after that
                they’re permanently deleted
              </p>
            </div>
          )}
        </div>

        <div className="w-full flex flex-col items-start gap-2">
          {isLoading ? (
            <Fragment>
              {Array.from({ length: 5 }).map((_, index) => (
                <div
                  key={index}
                  className="animate-pulse w-full flex items-center bg-[var(--backgrounds-default)] border justify-between px-3 h-[48px] rounded-[8px] border-[var(--border-on-bg)]"
                >
                  <div className="flex items-center justify-between gap-x-2 w-fit">
                    <div className="animate-pulse aspect-square h-5 w-5 rounded bg-[var(--backgrounds-on-bg)]"></div>
                    <div className="animate-pulse rounded h-5 w-[200px] bg-[var(--backgrounds-on-bg)]"></div>
                  </div>

                  <div className="w-fit gap-x-2 flex items-center justify-start">
                    <div className="animate-pulse rounded h-5 w-[100px] bg-[var(--backgrounds-on-bg)]"></div>
                    <div className="animate-pulse rounded h-5 w-[100px] bg-[var(--backgrounds-on-bg)]"></div>
                    <div className="animate-pulse rounded h-5 w-[20px] bg-[var(--backgrounds-on-bg)]"></div>
                  </div>
                </div>
              ))}
            </Fragment>
          ) : (
            <Fragment>
              {filteredProjects.map((project: IProject) => (
                <div
                  key={project.id}
                  className={projectItemStyles({
                    type: `${currentTab !== 'ACTIVE' ? 'notActive' : 'active'}`,
                  })}
                >
                  <div className="flex items-center justify-start w-fit gap-x-2">
                    <div
                      className="flex items-center justify-center aspect-square h-5 w-5 rounded uppercase text-body-micro bold"
                      style={colorStyles[getColorTag(project?.name)]}
                    >
                      {project?.name.charAt(0)}
                    </div>
                    <div className="text-body-small medium text-[var(--text-default)] max-w-[250px] truncate">
                      {project?.name}
                    </div>
                    {currentTab === 'ACTIVE' && (
                      <Fragment>
                        {!project?.isPublic && <Lock className="w-3 h-3" />}
                      </Fragment>
                    )}
                    {currentTab === 'DELETED' && (
                      <Badge
                        value={`${differenceInDays(
                          new Date(project.deleteAt),
                          new Date()
                        )} days left`}
                      />
                    )}
                  </div>

                  {currentTab === 'ACTIVE' && (
                    <div className="w-fit gap-x-2 grid grid-cols-[1fr_20px] items-end">
                      <StatusTag
                        color={getProjectStatusColor[project?.status]}
                        value={transformToTitleCase(project?.status)}
                        size="medium"
                        leftIcon={<Circle />}
                      />

                      <Avatar
                        name={
                          project.owner?.detail?.fullName ??
                          project.owner?.email
                        }
                        src={project.owner?.detail?.avatar?.url}
                        alt={project.owner?.detail?.fullName}
                        size={20}
                      />
                    </div>
                  )}

                  {currentTab !== 'ACTIVE' && (
                    <Fragment>
                      {userId === project.owner?.id ? (
                        <Button
                          btnType="neutral"
                          size="small"
                          className="w-fit"
                          onClick={() => handleRestoreProject(project.id)}
                          value={'Restore'}
                          disabled={loadingRestore[project.id]}
                          loading={loadingRestore[project.id]}
                        />
                      ) : (
                        <Button
                          btnType="ghost"
                          size="small"
                          className="w-fit"
                          value={'Locked'}
                          disabled={true}
                        />
                      )}
                    </Fragment>
                  )}
                </div>
              ))}
            </Fragment>
          )}
        </div>
      </section>
    </div>
  );
}

const projectItemStyles = tv({
  base: 'w-full flex items-center bg-[var(--backgrounds-default)] border justify-between px-3 h-[48px] rounded-[8px]',
  variants: {
    type: {
      active: 'border-[var(--border-default)]',
      notActive: 'border-[var(--border-on-bg)]',
    },
  },
  defaultVariants: {
    type: 'active',
  },
});
