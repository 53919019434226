const config = {
  content: ['./src/**/*.{js,jsx,ts,tsx}'],
  theme: {
    extend: {
      fontFamily: {
        sans: ['Inter', 'sans-serif'],
        graphik: ['Graphik', 'sans-serif'],
      },
      backgroundImage: {
        'gradient-radial': 'radial-gradient(var(--tw-gradient-stops))',
        'gradient-conic':
          'conic-gradient(from 180deg at 50% 50%, var(--tw-gradient-stops))',
        'gradient-summary':
          'linear-gradient(119.76deg, #E56B5D 0.79%, #8178EF 97.24%)',
      },
      colors: {
        grey: {
          25: '#F9FAFB',
          50: '#F2F2F5',
          75: '#EAE9ED',
          100: '#F0F2F5',
          200: '#9693A5',
          300: '#65607B',
          400: '#373053',
          500: '#667185',
          700: '#344054',
          900: '#101928',
        },
        primary: {
          100: '#E1DFFB',
          200: '#B0AAF5',
          500: '#8178EF',
          600: '#6E66CB',
          700: '#5C55AA',
          800: '#1D2739',
          900: '#3A366C',
        },
        blue: {
          50: '#EAF0FF',
          500: '#2C66FF',
          900: '#122B6B',
        },
        warning: {
          50: '#FEF6E7',
          75: '#FBE2B7',
          100: '#F7D394',
          500: '#DD900D',
          700: '#865503',
          900: '#523300',
        },
        negative: {
          50: '#FBEAE9',
          300: '#DD524D',
          500: '#CB1A14',
          600: '#BA110B',
          700: '#9E0A05',
          900: '#591000',
        },
        success: {
          50: '#E7F6EC',
          500: '#099137',
          900: '#004617',
        },
      },
    },
  },
  plugins: [
    require('@tailwindcss/typography'),
    require('@tailwindcss/forms'),
    require('tailwindcss-animate'),
  ],
};
export default config;
