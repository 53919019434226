import { ApolloProvider as BaseApolloProvider } from '@apollo/client';
import React, { useState } from 'react';
import { useAuth } from '../context/auth.context';
import { createApolloClient } from '../lib/apolloClient';

export const ApolloProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { refreshAuthToken, logout } = useAuth();
  const [client] = useState(() => createApolloClient(refreshAuthToken, logout));

  return <BaseApolloProvider client={client}>{children}</BaseApolloProvider>;
};
