import { FormEvent, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useInviteTeamToOneWorkspace } from 'src/api/workspace';
import Button from 'src/components/ui/Button/Button';
import Validator from 'validatorjs';
import ValidationErrorMessages from '../components/ValidationErrorMessages';

import toast from 'react-hot-toast';
import ToastMessage from 'src/components/ToastMessage';
import { useUser } from 'src/context/user.context';

type FormData = {
  emails: string;
};

function InviteToWorkspacePage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const workspaceSlug = searchParams.get('workspace');
  const { userSetupComplete } = useUser();

  const [formData, setFormData] = useState<FormData>({
    emails: '',
  });
  const [formErrors, setFormErrors] =
    useState<Validator.ValidationErrors | null>(null);

  const [inviteTeamToOneWorkspace, { loading, error }] =
    useInviteTeamToOneWorkspace();
  const errorMessage = error && `Submission error! ${error.message}`;
  const disabled = loading || !workspaceSlug;

  const handleInputChange = (field: string, value: any) => {
    const updatedData = { ...formData, [field]: value };
    setFormData(updatedData);
    setFormErrors(null);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const validation = new Validator(formData, {
      emails: [
        'required',
        'regex:/^\\s*([-\\w.+]+@[a-zA-Z\\d.-]+\\.[a-zA-Z]{2,})(\\s*,\\s*([-\\w.+]+@[a-zA-Z\\d.-]+\\.[a-zA-Z]{2,}))*\\s*$/',
      ],
    });

    if (validation.passes()) {
      setFormErrors(null);
      const emails = formData.emails.split(/\s*,\s*/);

      await inviteTeamToOneWorkspace({
        variables: {
          data: {
            emailRoles: emails.map((email) => ({ email })),
          },
          where: { slug: workspaceSlug },
        },
        onCompleted: () => {
          toast((t) => (
            <ToastMessage
              id={t.id}
              title={`Your invitation was sent successfully`}
            />
          ));

          if (!userSetupComplete) {
            navigate(`/workspace/${workspaceSlug}/get-started`);
            return;
          }

          navigate(`/workspace/${workspaceSlug}/project`);
        },
        onError: (error) => {
          toast((t) => <ToastMessage id={t.id} title={`${error.message}`} />);
        },
      });
    } else {
      setFormErrors(validation.errors.all());
    }
  };

  return (
    <div className="flex flex-col gap-10 md:gap-14">
      <div className="w-full flex flex-col gap-4 items-start">
        <div className="flex flex-col gap-2 items-start">
          <h3 className="text-heading-3-small bold text-[var(--text-default)] md:text-heading-3">
            Invite team members
          </h3>
          <p className="text-body-small regular text-[var(--text-neutral)]">
            Invite your team members to your workspace to start collaborating.
          </p>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="w-full flex flex-col gap-6">
        <div className="space-y-2">
          <label
            htmlFor="emails"
            className="text-body-small regular text-[var(--text-default)]"
          >
            Email address
          </label>
          <div className="space-y-1">
            <textarea
              autoFocus
              id="emails"
              className="w-full input min-h-20"
              disabled={disabled}
              value={formData.emails}
              onChange={(e) => handleInputChange('emails', e.target.value)}
              placeholder="daniel@gmail.com, elijah@gmail.com"
              rows={3}
            ></textarea>

            <ValidationErrorMessages name="emails" errors={formErrors} />

            {errorMessage && (
              <p className="text-xs text-red-500">{errorMessage}</p>
            )}

            <p className="text-body-micro regular text-[var(--text-neutral)]">
              You can invite multiple emails at once with this format{' '}
              <span className="medium text-[var(--text-default)]">
                email1@example.com, email2@example.com, email3@example.com
              </span>
            </p>
          </div>
        </div>

        <Button
          type="submit"
          btnType="primary"
          size="large"
          value="Invite"
          loading={loading}
          disabled={disabled}
          className="w-full"
        />
        <Link to={`/workspace/${workspaceSlug}/project`}>
          <Button
            type="submit"
            btnType="ghost"
            size="large"
            value="I'll do this later"
            disabled={disabled}
            className="w-full"
          />
        </Link>
      </form>
    </div>
  );
}

export default InviteToWorkspacePage;
