import { format } from 'date-fns';
import { Fragment, useCallback, useState } from 'react';
import { toast } from 'react-hot-toast';
import { ReactComponent as Bank } from 'src/assets/svgs/ImgBank.svg';
import { ReactComponent as Bolt } from 'src/assets/svgs/ImgBolt.svg';
import { ReactComponent as Fire } from 'src/assets/svgs/ImgFire.svg';
import { ReactComponent as Gift } from 'src/assets/svgs/ImgGift.svg';
import PricingCard from 'src/components/billing/PricingCard';
import ToastMessage from 'src/components/ToastMessage';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/Table';
import { IPricingPlan, IWorkspacePlan } from 'src/types';

import { useCreateCheckoutSession } from 'src/api/misc/misc.service';
import HeaderText from 'src/components/ui/HeaderTexts';
import HorizontalDivider from 'src/components/ui/HorizontalDivider';
import StatusTag from 'src/components/ui/tags/StatusTags';
import { useUser } from 'src/context/user.context';
import { useWorkspaceContext } from 'src/context/workspace.context';
import {
  formatAmount,
  getCurrencySymbol,
  transformToTitleCase,
} from 'src/lib/helpers';
import { findCheckoutDetailsByTypeAndCycle } from 'src/utils/workspace/workspaceUtils';

type BillingCycle = 'MONTHLY' | 'ANNUALLY';

const PRICING_PLANS: IPricingPlan[] = [
  {
    price: 0,
    currency: '$',
    type: 'FREE',
    planName: 'Free',
    icon: <Gift />,
    description: 'While we’re in early access',
    features: [
      { name: 'Up to 5 projects', comingSoon: false },
      { name: 'Up to 200 posts', comingSoon: false },
      { name: 'Up to 5 members', comingSoon: false },
      { name: 'Tasks', comingSoon: false },
      { name: 'Check-in', comingSoon: false },
      { name: 'Reminders', comingSoon: false },
    ],
    button: {
      label: 'Upgrade',
      href: '',
    },
  },
  {
    price: 8,
    currency: '$',
    type: 'BASIC',
    planName: 'Basic',
    icon: <Bolt />,
    description: 'For small teams',
    features: [
      { name: 'Everything in Free plus...', comingSoon: false },
      { name: 'Unlimited projects', comingSoon: false },
      { name: 'Unlimited posts', comingSoon: false },
      { name: 'Unlimited members', comingSoon: false },
      { name: 'Set roles & permissions', comingSoon: false },
    ],
    button: {
      label: 'Upgrade',
      href: '',
    },
  },
  {
    price: 12,
    currency: '$',
    type: 'PRO',
    planName: 'Pro',
    icon: <Fire />,
    description: 'For growing teams',
    features: [
      { name: 'Everything on Basic plus...', comingSoon: false },
      { name: 'Summarise', comingSoon: false },
      { name: 'Member profiles', comingSoon: false },
      { name: 'Custom domains', comingSoon: false },
    ],
    button: {
      label: 'Upgrade',
      href: '',
    },
  },
  {
    price: 25,
    currency: '$',
    type: 'ENTERPRISE',
    planName: 'Enterprise',
    icon: <Bank />,
    description: 'For large organisations',
    features: [
      { name: 'Everything on Pro plus...', comingSoon: false },
      { name: 'Round-up', comingSoon: false },
      { name: 'Catch-up ', comingSoon: false },
      { name: 'SSO ', comingSoon: false },
      { name: 'Personalised onboarding', comingSoon: false },
      { name: 'Priority support', comingSoon: false },
    ],
    button: {
      label: 'Contact us',
      href: 'mailto:hello@swing.studio',
    },
  },
];

type LoadingState = {
  [key: string]: boolean;
};

export default function BillingPage() {
  const { user } = useUser();
  const { workspacePlans, workspace } = useWorkspaceContext();
  const [loadingPlans, setLoadingPlans] = useState<LoadingState>({});
  const [selectedCycle, setSelectedCycle] = useState<BillingCycle>('ANNUALLY');

  const [createCheckoutSession] = useCreateCheckoutSession();

  // const currentPlanDetails = useMemo(
  //   () =>
  //     findPlanDetailsByActivePlan(
  //       PRICING_PLANS,
  //       workspace?.plan?.name as string
  //     ),
  //   [workspace?.plan?.name]
  // );

  const handleCheckout = useCallback(
    async (planType: string, planCycle: string) => {
      if (!planType || !planCycle) return;

      try {
        setLoadingPlans((prev) => ({ ...prev, [planType]: true }));

        const planCheckoutDeets = findCheckoutDetailsByTypeAndCycle(
          workspacePlans as IWorkspacePlan[],
          planType,
          planCycle
        );

        if (!planCheckoutDeets) return;

        await createCheckoutSession({
          variables: {
            data: {
              planId: planCheckoutDeets?.id,
              userId: user?.id,
              workspaceId: workspace?.id,
            },
          },
          onCompleted: (data) => {
            if (data?.createCheckoutSession) {
              window.open(data.createCheckoutSession, '_blank');
            }
          },
        });
      } catch (error: any) {
        console.log(error);
        toast((t) => <ToastMessage id={t.id} title={`${error.message}`} />);
      } finally {
        setLoadingPlans((prev) => ({ ...prev, [planType]: false }));
      }
    },
    [user?.id, workspace?.id, workspacePlans, createCheckoutSession]
  );

  const calculateEstimatedBill = useCallback(() => {
    if (!workspace?.members) return 0;

    const totalMembers = workspace?.members.length;
    const planPrice = workspace?.plan?.pricePerUser || 0;

    return totalMembers * planPrice;
  }, [workspace?.members, workspace?.plan?.pricePerUser]);

  const estimatedBill = calculateEstimatedBill();

  const statusColorSetter = (status: string) => {
    switch (status) {
      case 'PENDING':
        return 'warning';
      case 'PAID':
        return 'success';
      case 'FAILED':
        return 'negative';
      default:
        return 'message';
    }
  };

  return (
    <div className="w-full flex flex-col gap-10 max-w-[1440px]">
      <div className="flex flex-col items-start gap-4">
        <HeaderText title="Billing" />
        <HorizontalDivider />
      </div>

      {workspace?.plan?.name !== 'FREE' && (
        <Fragment>
          <div className="flex flex-col gap-6 items-start">
            <HeaderText
              subTitle="Billing details"
              snippet="See your billing and issue details."
            />
            <div className="grid grid-cols-3 w-full gap-2">
              <BillDetailCard title="Next issue date" value="Sept 14, 2024" />
              <BillDetailCard
                title="Monthly seats"
                value={String(workspace?.members?.length) || '0'}
              />
              <BillDetailCard
                title="Total bill"
                value={`${getCurrencySymbol(
                  String(workspace?.plan?.currency)
                )}${estimatedBill}`}
              />
            </div>
            {(workspace?.billings?.length ?? 0) > 0 && (
              <div className="w-full">
                <Table>
                  <TableHeader>
                    <TableRow className="border-none">
                      <TableHead className="w-[220px] rounded-l-[8px]">
                        Date
                      </TableHead>
                      <TableHead className="w-[220px]">Seats</TableHead>
                      <TableHead className="w-[220px]">Plan</TableHead>
                      <TableHead className="w-[220px]">Status</TableHead>
                      <TableHead className="w-[220px] rounded-r-[8px]">
                        Total invoice
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {workspace?.billings
                      ?.slice()
                      .sort(
                        (a, b) =>
                          new Date(b.createdAt).getTime() -
                          new Date(a.createdAt).getTime()
                      )
                      .map((billing) => (
                        <TableRow
                          key={`${billing.createdAt} + ${crypto.randomUUID()}`}
                        >
                          <TableCell className="h-16">
                            {format(billing?.createdAt, 'MMM dd, yyyy')}
                          </TableCell>
                          <TableCell className="h-16">2</TableCell>
                          <TableCell className="h-16">
                            {transformToTitleCase(
                              billing?.plan?.name as string
                            )}
                          </TableCell>
                          <TableCell className="h-16">
                            <StatusTag
                              size="medium"
                              color={statusColorSetter(billing?.status) as any}
                              value={transformToTitleCase(billing?.status)}
                              className="text-body-micro"
                            />
                          </TableCell>
                          <TableCell className="h-16">
                            <div className="flex items-center gap-2.5">
                              <span>
                                {formatAmount(
                                  billing?.currency,
                                  billing?.amountPaid
                                )}
                              </span>
                              {['PENDING', 'FAILED'].includes(
                                billing?.status
                              ) && (
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleCheckout(
                                      billing?.plan?.name as string,
                                      billing?.plan?.billingCycle
                                    );
                                  }}
                                  className="w-fit text-body-small font-medium text-[var(--button-primary)]"
                                >
                                  Pay invoice →
                                </button>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </div>
          <HorizontalDivider />
        </Fragment>
      )}

      <div className="w-full flex flex-col gap-6 items-start">
        <div className="w-full flex items-center justify-between">
          <HeaderText
            title="Current plan"
            badge={
              <StatusTag
                size="medium"
                value={transformToTitleCase(String(workspace?.plan?.name))}
                color="message"
              />
            }
            snippet="Select a preferred plan for your team."
          />
          <div className="flex w-[200px] cursor-pointer p-0.5 gap-2 items-center justify-between rounded-lg bg-[var(--backgrounds-hover-clicked)] shadow-[0px_1px_4px_0px_rgba(101,_96,_123,_0.05)_inset]">
            <TabItem
              label="Monthly"
              active={selectedCycle === 'MONTHLY'}
              onClick={() => setSelectedCycle('MONTHLY')}
            />
            <TabItem
              label="Yearly"
              active={selectedCycle === 'ANNUALLY'}
              onClick={() => setSelectedCycle('ANNUALLY')}
            />
          </div>
        </div>
        <div
          className={`grid gap-x-4 w-full ${
            workspace?.plan?.name !== 'FREE' ? 'grid-cols-3' : 'grid-cols-4'
          }`}
        >
          {PRICING_PLANS.filter(
            (plan) =>
              !(workspace?.plan?.name !== 'FREE' && plan.type === 'FREE')
          ).map((plan) => (
            <PricingCard
              plan={plan}
              activePlanType={workspace?.plan?.name as string}
              selectedCycle={selectedCycle}
              isLoading={loadingPlans[plan.type]}
              onClick={() => {
                handleCheckout(plan.type as string, selectedCycle as string);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

interface ITabItem {
  label: string;
  active: boolean;
  onClick: () => void;
}

interface IBillDeetCard {
  title: string;
  value: string;
}

function BillDetailCard({ title, value }: IBillDeetCard) {
  return (
    <div className="w-full h-[107px] bg-[var(--backgrounds-on-canvas)] border border-[var(--border-on-bg)] rounded-lg flex flex-col items-start gap-y-2 justify-center px-6">
      <small className="text-body-small medium text-[var(--text-disabled)]">
        {title}
      </small>
      <h4 className="text-heading-4 font-semibold text-[var(--text-default)]">
        {value}
      </h4>
    </div>
  );
}

function TabItem({ label, active, onClick }: ITabItem) {
  return (
    <div
      onClick={onClick}
      className={`flex w-full max-w-[94px] text-body-small h-7 justify-center items-center rounded-md ${
        active
          ? 'bg-[var(--backgrounds-on-bg)] text-[var(--text-default)]'
          : 'text-[var(--text-neutral)]'
      }`}
    >
      {label}
    </div>
  );
}
