import { useLocalStorage } from '@uidotdev/usehooks';
import { AnimatePresence, motion } from 'framer-motion';
import { Fragment, useState } from 'react';
import toast from 'react-hot-toast';
import {
  Close,
  MegaPhone,
  Plus,
  // ThumbsDown,
  // ThumbsUp,
  UserCircle,
  WorkStream,
} from 'src/assets/icons';
import { ReactComponent as AiLogo } from 'src/assets/svgs/ImgAISummary.svg';
import UserDetailItem from 'src/components/common/project/UserDetailItem';
import ToastMessage from 'src/components/ToastMessage';
import IconButton from 'src/components/ui/Button/IconButton';
import { usePostsContext } from 'src/context/post.context';
import useProjectFilters from 'src/hooks/project/useProjectFilters';
import useMobile from 'src/hooks/useMobile';
import {
  renderLineBreaks,
  transformToTitleCase,
  userExistAsCollaborator,
} from 'src/lib/helpers';
import { IProjectTopic, PostType } from 'src/lib/types';
import { IPost, IProject, IUser } from 'src/types';
import SectionWrap from '../../common/SectionWrap';
import Button from '../../ui/Button/Button';
import HorizontalDivider from '../../ui/HorizontalDivider';
import ProjectFilterDropdown from '../filter/ProjectFilterDropdown';
import DeletePostModal from './DeletePostModal';
import PostItem from './PostItem';
import PostModal from './PostModal';
import SkeletalPostSection from './SkeletalPostSection';

interface IState {
  editPost?: {
    show: boolean;
    post: IPost | undefined;
  };
  deletePost?: {
    show: boolean;
    post: IPost | undefined;
  };
}

interface IPostArena {
  myData?: any;
  projectData: IProject;
  handleCreatePost: () => void;
  workspaceSlug: string;
  heightHeadingContainerRef: number;
  collaborators?: IUser[];
  workStreams?: IProjectTopic[];
  isJoinedSuccess?: boolean;
}

const postTypes = Object.values(PostType);

function PostsArena(props: IPostArena) {
  const {
    myData,
    projectData,
    handleCreatePost,
    heightHeadingContainerRef,
    collaborators = [],
    workStreams = [],
    isJoinedSuccess,
  } = props;

  const [userId] = useLocalStorage('userId', undefined);
  const userCollaborates = userExistAsCollaborator(userId, projectData);

  const [state, setState] = useState<IState>({
    editPost: {
      show: false,
      post: undefined,
    },
    deletePost: undefined,
  });

  const isMobile = useMobile();
  const { posts, isLoading, isCatchingUp, catchUpData, setCatchUpData } =
    usePostsContext();

  const {
    filteredItems: filteredPosts,
    filterCriteria,
    setFilterCriteria,
  } = useProjectFilters<IPost>(posts || [], { itemType: 'post' as const });

  const handleCopyPost = (post: IPost) => () => {
    const origin = window.location.origin;
    const project = post.project;
    const workspace = project.workspace;
    const link = `${origin}/workspace/${workspace.slug}/project/${project.id}/posts/${post.id}`;

    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast(
          (t) => (
            <ToastMessage
              id={t.id}
              title={'Link to post is copied to clipboard'}
            />
          ),
          { duration: 50000 }
        );
      })
      .catch((err) => {
        toast((t) => (
          <ToastMessage id={t.id} title={'Error copying text to clipboard.'} />
        ));
      });
  };

  if (!!posts?.length) {
    return (
      <SectionWrap
        style={{
          height: !isMobile
            ? `calc(100vh - ${heightHeadingContainerRef}px)`
            : '100%',
        }}
        className="flex flex-col md:max-h-full !rounded-[0_0_8px_8px]"
      >
        <div className="w-full flex justify-between mt-6 pb-2 items-start px-6">
          <div className="w-max flex items-start gap-x-2">
            <ProjectFilterDropdown
              value={filterCriteria.filteredWorkstreams}
              onChange={(newWorkstreams: string[]) =>
                setFilterCriteria({
                  ...filterCriteria,
                  filteredWorkstreams: newWorkstreams,
                })
              }
              icon={<WorkStream className="w-3 h-3" />}
              label="Workstreams"
              options={
                workStreams.map((workStream: any) => ({
                  label: workStream.name,
                  value: workStream.id,
                })) || []
              }
            />
            <ProjectFilterDropdown
              value={filterCriteria.filteredTypes || []}
              onChange={(newTypes: string[]) =>
                setFilterCriteria({
                  ...filterCriteria,
                  filteredTypes: newTypes,
                })
              }
              icon={<MegaPhone className="w-3 h-3" />}
              label="Type"
              options={
                postTypes.map((type: string) => ({
                  label: transformToTitleCase(type),
                  value: type,
                })) || []
              }
            />
            <ProjectFilterDropdown
              value={filterCriteria.filteredMembers}
              onChange={(newMembers: string[]) =>
                setFilterCriteria({
                  ...filterCriteria,
                  filteredMembers: newMembers,
                })
              }
              className="min-w-[250px]"
              icon={<UserCircle className="w-3 h-3" />}
              label="Members"
              options={
                collaborators?.map((collaborator) => ({
                  label: collaborator.detail?.fullName ?? collaborator.email,
                  value: collaborator.id,
                  other: collaborator,
                })) || []
              }
              customOptionItem={(option) => (
                <UserDetailItem
                  wrapItem={false}
                  userName={option.label as string}
                  userPosition={option.other.detail.position as string}
                  avatarUrl={option.other.detail.avatar?.url}
                />
              )}
            />
          </div>
          <div
            className="min-w-[57px] p-[1.5px] min-h-[27px] invisible"
            aria-label="invisible block"
          />
        </div>

        {isCatchingUp || catchUpData !== null ? (
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              layoutId="catchup-post"
              className="w-full py-5 px-6 flex items-start flex-col gap-y-4"
            >
              <div className="w-full flex justify-between items-center">
                <div className="flex items-center gap-x-2.5">
                  <AiLogo />
                  <small className="text-body-small font-medium bg-gradient-summary inline-block text-transparent bg-clip-text">
                    {isCatchingUp ? 'Catching up' : 'All Caught up'}
                  </small>
                </div>

                {catchUpData !== null && (
                  <IconButton
                    type="ghost"
                    size="micro"
                    onClick={() => {
                      setCatchUpData(null);
                    }}
                    icon={<Close />}
                  />
                )}
              </div>

              {!isCatchingUp && catchUpData !== null && (
                <Fragment>
                  <div className="w-full border border-[var(--border-on-bg)] bg-[var(--backgrounds-default)] p-4 rounded-[12px]">
                    <div className="text-body-small text-[var(--text-on-bg)] font-medium">
                      {renderLineBreaks(catchUpData)}
                    </div>
                  </div>

                  {/* <div className="w-full flex items-center gap-x-2.5 justify-center">
                    <IconButton
                      type="ghost"
                      size="micro"
                      rounded="micro"
                      onClick={() => {}}
                      icon={<ThumbsUp />}
                      className="!h-5 !w-5 outline"
                    />
                    <IconButton
                      type="ghost"
                      size="micro"
                      onClick={() => {}}
                      icon={<ThumbsDown />}
                      className="!h-5 !w-5 outline"
                    />
                  </div> */}
                </Fragment>
              )}

              {isCatchingUp && (
                <div className="w-full flex flex-col items-start gap-y-1">
                  <div
                    className="h-5 w-[176px] rounded-lg animate-pulse"
                    style={{
                      background: 'var(--skeleton-bg)',
                    }}
                  />
                  <div
                    className="h-5 w-full rounded-lg animate-pulse"
                    style={{
                      background: 'var(--skeleton-bg)',
                    }}
                  />
                  <div
                    className="h-5 w-full rounded-lg animate-pulse"
                    style={{
                      background: 'var(--skeleton-bg)',
                    }}
                  />
                </div>
              )}
            </motion.div>
            <HorizontalDivider />
          </AnimatePresence>
        ) : null}

        <AnimatePresence>
          {isJoinedSuccess && (
            <motion.div
              layoutId="join-success"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="!py-4 w-full grid place-items-center border-b border-[var(--border-divider)]"
            >
              <p className="text-body-micro medium text-[var(--text-neutral)]">
                You just joined “{projectData.name}”
              </p>
            </motion.div>
          )}
        </AnimatePresence>

        {filteredPosts.length > 0 ? (
          <div className="h-full overflow-y-auto mb-[92px]">
            <ul className="flex flex-col pb-[100px] flex-grow">
              {filteredPosts.map((post: IPost, index: number) => (
                <li key={`${post.id}-${index}`} className="w-full">
                  <PostItem
                    post={post}
                    handleCopy={handleCopyPost(post)}
                    handleEdit={() =>
                      setState({
                        ...state,
                        editPost: { show: true, post },
                      })
                    }
                    handleDelete={() =>
                      setState({
                        ...state,
                        deletePost: { show: true, post },
                      })
                    }
                  />
                  <HorizontalDivider />
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-full pb-[92px]">
            <MegaPhone className="w-8 h-8" />
            <div className="space-y-2 flex flex-col items-center">
              <div className="text-heading-6 medium text-[var(--text-default)]">
                No posts found
              </div>
              <p className="text-body-small regular text-[var(--text-neutral)]">
                No posts found with the selected filters.
              </p>
            </div>
          </div>
        )}

        <PostModal
          initialPost={state.editPost?.post}
          show={Boolean(state.editPost?.show)}
          handleClose={() =>
            setState({
              ...state,
              editPost: {
                show: false,
                post: undefined,
              },
            })
          }
          collaborators={collaborators}
        />

        <DeletePostModal
          post={state.deletePost?.post}
          show={Boolean(state.deletePost?.show)}
          handleClose={() =>
            setState({
              ...state,
              deletePost: {
                show: false,
                post: undefined,
              },
            })
          }
        />
      </SectionWrap>
    );
  }

  return (
    <Fragment>
      <SectionWrap
        className="flex flex-col !rounded-[0_0_8px_8px]"
        style={{
          height: isLoading
            ? `calc(100vh - ${heightHeadingContainerRef}px)`
            : '100%',
        }}
      >
        {isLoading ? (
          <SkeletalPostSection myData={myData} />
        ) : (
          <div className="text-center flex justify-center flex-col items-center space-y-6 h-full pb-[92px]">
            <MegaPhone className="w-8 h-8" />
            <div className="space-y-2 flex flex-col">
              <div className="text-heading-6 medium text-[var(--text-default)]">
                No posts yet
              </div>
              <p className="max-w-64 text-body-small regular text-[var(--text-neutral)]">
                Start the conversation with a question or an update on ongoing
                work.
              </p>
            </div>
            <Button
              size="medium"
              btnType="primary"
              icon="left"
              value={'Write a post'}
              className={'w-auto'}
              onClick={() => {
                if (!userCollaborates) {
                  toast((t) => (
                    <ToastMessage
                      id={t.id}
                      title={'Only collaborators can add posts'}
                    />
                  ));
                  return;
                }
                handleCreatePost();
              }}
            >
              <Plus />
            </Button>
          </div>
        )}
      </SectionWrap>
    </Fragment>
  );
}

export default PostsArena;
