import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from 'src/context/auth.context';
import { UserProvider } from 'src/context/user.context';

function ProtectedRoute() {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  return (
    <UserProvider>
      <Outlet />
    </UserProvider>
  );
}

export default ProtectedRoute;
