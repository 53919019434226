import { Fragment, useState } from 'react';
// import Button from 'src/components/ui/Button/Button';
// import StatusTag from 'src/components/ui/tags/StatusTags';
import { useComment } from 'src/hooks/project/post/useCommentAction';
import { getUniqueCommentAuthors } from 'src/lib/helpers';
import { cn } from 'src/lib/utils';
import { IComment, IUser } from 'src/types';
import CommentInputField from '../CommentInputField';
import PostCommentBody from './PostCommentBody';
import ReplyCommentBody from './ReplyCommentBody';

interface IPostCommentItemProps {
  myData: IUser | null;
  collaborators: IUser[];
  userCollaborates?: boolean;
  comment: IComment;
  postId: string;
}

function PostCommentItem({
  myData,
  collaborators,
  userCollaborates,
  comment,
  postId,
}: IPostCommentItemProps) {
  const commentAuthors = getUniqueCommentAuthors(comment.responses);
  const [showMenu, setShowMenu] = useState(false);
  const [showReplyMenu, setShowReplyMenu] = useState(false);

  const {
    watch,
    control,
    handleSubmit,
    handleCreateComment,
    handleEditorChange,
    isCreatingComment: loading,
    editorConfig,
    initializeReplyEditor,
    handleMentionButtonClick,
    handleEmojiClick,
  } = useComment({
    postId: postId as string,
    onReplyCreated: () => {},
    collaborators,
    editorPlaceholder: 'Add a reply',
  });

  const formValues = watch();

  return (
    <Fragment>
      <div
        onMouseEnter={() => setShowMenu(true)}
        onMouseLeave={() => setShowMenu(false)}
        className="duration-1000 flex flex-col gap-4 post-comment-item"
      >
        <PostCommentBody
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          comment={comment}
          collaborators={collaborators}
        />

        <div
          className={cn(
            'w-full rounded-[12px] border flex flex-col gap-y-4',
            comment.responses.length < 1
              ? 'border-[transparent]'
              : 'p-6 border-[var(--border-divider)]'
          )}
        >
          {comment.responses.length > 0 && (
            <div className="flex flex-col">
              {comment.responses.map((response, index) => (
                <div
                  key={response.id}
                  onMouseEnter={() => setShowReplyMenu(true)}
                  onMouseLeave={() => setShowReplyMenu(false)}
                >
                  <ReplyCommentBody
                    comment={response}
                    showMenu={showReplyMenu}
                    setShowMenu={setShowReplyMenu}
                    userCollaborates={userCollaborates}
                    collaborators={collaborators}
                    isLastReply={index === comment.responses.length - 1}
                  />
                </div>
              ))}
            </div>
          )}

          <CommentInputField
            name="reply"
            control={control}
            disabled={!userCollaborates}
            onReady={(editor) => initializeReplyEditor(editor, comment.id)}
            handleEditorChange={handleEditorChange}
            editorConfig={editorConfig}
            loadingBtn={loading}
            disabledBtn={loading || !formValues.reply}
            onMentionClick={handleMentionButtonClick}
            onEmojiClick={handleEmojiClick}
            onClick={handleSubmit((data) => {
              handleCreateComment(data, true, comment.id);
            })}
            btnLabel={'Reply'}
          />
        </div>
      </div>
    </Fragment>
  );
}

export default PostCommentItem;
