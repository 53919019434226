import { useMutation, useQuery } from '@apollo/client';
import { READ_ALL_INBOX, READ_ONE_INBOX } from 'src/lib/mutations';
import { INBOX, INBOX_COUNT } from 'src/lib/queries';

export function useInbox(args?: object) {
  return useQuery(INBOX, {
    fetchPolicy: 'network-only' as const,
    ...args,
  });
}

export function useInboxCount(args?: object) {
  return useQuery(INBOX_COUNT, {
    fetchPolicy: 'network-only' as const,
    ...args,
  });
}

export function useReadAllInbox() {
  return useMutation(READ_ALL_INBOX, {
    refetchQueries: [INBOX, INBOX_COUNT],
  });
}

export function useReadOneInbox() {
  return useMutation(READ_ONE_INBOX, {
    refetchQueries: [INBOX_COUNT, INBOX],
  });
}
