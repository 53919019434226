import { useLocalStorage } from '@uidotdev/usehooks';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useUpdateOneUserDetail } from 'src/api/user/user.service';
import ProfileDetail from 'src/components/features/Profile/ProfileDetail';
import ProfilePicture from 'src/components/features/Profile/ProfilePicture';
import ProfileSecurity from 'src/components/features/Profile/ProfileSecurity';
import ToastMessage from 'src/components/ToastMessage';
import HeaderText from 'src/components/ui/HeaderTexts';
import HorizontalDivider from 'src/components/ui/HorizontalDivider';
import { useUser } from 'src/context/user.context';
import { useWorkspaceContext } from 'src/context/workspace.context';
import '../settings.css';

// refactor this (use RHF and check the diff fields) #reminder

export default function ProfilePage() {
  const { user } = useUser();
  const { userWorkspaces } = useWorkspaceContext();
  const [userId] = useLocalStorage('userId', undefined);
  const [updateOneUserDetail, { loading: updateOneUserDetailLoading }] =
    useUpdateOneUserDetail();

  const [userName, setUserName] = useState<string>('');
  const [email, setEmail] = useState('');
  const [userRole, setUserRole] = useState('');
  const [profileImageUrl, setProfileImageUrl] = useState<string | undefined>();

  async function handleUpdateUserNameAndRole() {
    await updateOneUserDetail({
      variables: {
        data: {
          fullName: {
            set: userName,
          },
          position: {
            set: userRole,
          },
        },
        where: {
          id: userId,
        },
      },
      onCompleted: ({ data }) => {
        toast((t) => (
          <ToastMessage
            id={t.id}
            title="Profile details updated successfully"
          />
        ));
      },
      onError: (error) => {
        toast((t) => <ToastMessage id={t.id} title={`${error.message}`} />);
      },
    });
  }

  useEffect(() => {
    if (user) {
      setUserName(user.detail?.fullName);
      setUserRole(user.detail?.position);
      setEmail(user?.email);
      setProfileImageUrl(user.detail?.avatar?.url);
    }
  }, [user]);

  return (
    <div className="workspace-page flex flex-col gap-10">
      <div className="header_div">
        <HeaderText title="My profile" />
        <HorizontalDivider />
      </div>
      <div className="flex flex-col gap-6 items-start">
        <HeaderText
          subTitle="Profile picture"
          snippet="Update your profile picture."
        />
        <ProfilePicture
          name={userName}
          userId={userId}
          profileImageUrl={profileImageUrl}
          setProfileImageUrl={setProfileImageUrl}
        />
      </div>
      <HorizontalDivider />
      <div className="profile_details flex flex-col gap-6 items-start w-full">
        <HeaderText
          subTitle="Profile details"
          snippet="Update your profile name and role."
        />
        <ProfileDetail
          name={userName}
          setUserName={(arg) => setUserName(arg)}
          userRole={userRole}
          setUserRole={(arg) => {
            setUserRole(arg.value);
          }}
          loading={updateOneUserDetailLoading}
          handleUpdateUserNameAndRole={handleUpdateUserNameAndRole}
        />
        {/* <SelectInput
                    value={name}
                    style={{ maxHeight: '210px' }}
                    feedback=''
                    data={roleData}
                    label="What’s your role?"
                    placeholder="Enter your role"
                    onChange={handleNameChange}
                    onClick={DropdownChange}
                    closeBg={handleProfileImageChange} /> */}
      </div>
      <ProfileSecurity
        userEmail={email}
        userId={userId}
        userDetails={userWorkspaces}
      />
    </div>
  );
}
