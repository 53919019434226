import { CKEditor } from '@ckeditor/ckeditor5-react';
import BalloonEditor from 'ckeditor5-custom-build';
import { useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Attachment, IconMention } from 'src/assets/icons';

import Button from 'src/components/ui/Button/Button';
import IconButton from 'src/components/ui/Button/IconButton';
import { cn } from 'src/lib/utils';
import { IUser } from 'src/types';
import CustomEmojiPicker from './CustomEmojiPicker';

interface ICommentInputField {
  user?: IUser;
  showUserAvatar?: boolean;
  name: string;
  control: any;
  disabled?: boolean;
  onReady: (editor: any) => void;
  handleEditorChange: (editor: any) => void;
  editorConfig: any;
  loadingBtn: boolean;
  disabledBtn: boolean;
  onClick: () => void;
  btnLabel: string;
  isEditing?: boolean;
  cancelEditing?: () => void;
  onMentionClick?: () => void;
  onEmojiClick?: (icon: string) => void;
}

const CommentInputField = (props: ICommentInputField) => {
  const {
    name,
    control,
    disabled,
    onReady,
    handleEditorChange,
    editorConfig,
    loadingBtn,
    disabledBtn,
    onClick,
    btnLabel,
    isEditing,
    cancelEditing,
    onMentionClick,
    onEmojiClick,
  } = props;

  const editorWrapperRef = useRef<HTMLDivElement>(null);

  const [editorWrapperHeight, setEditorWrapperHeight] = useState(48);
  const [editorFocus, setEditorFocus] = useState(false);

  useEffect(() => {
    const updateEditorHeight = () => {
      if (editorWrapperRef.current) {
        const newHeight = editorWrapperRef.current.offsetHeight;
        if (newHeight !== editorWrapperHeight) {
          setEditorWrapperHeight(newHeight);
        }
      }
    };

    updateEditorHeight();

    window.addEventListener('resize', updateEditorHeight);

    return () => {
      window.removeEventListener('resize', updateEditorHeight);
    };
  }, [editorWrapperHeight]);

  return (
    <div
      className={cn(
        'flex relative w-full rounded-[16px] !bg-[var(--backgrounds-default)] border transition-all duration-200 items-center flex-col',
        editorFocus
          ? 'border-[var(--border-input-active)]'
          : 'border-[transparent]'
      )}
    >
      <div
        ref={editorWrapperRef}
        className={cn(
          '!w-full !bg-[var(--backgrounds-default)] rounded-[16px_16px_0_0] shadow-none min-h-11 ! transition-all duration-200 !max-h-[185px] overflow-y-auto pl-3 pr-2 bg-[red]'
          // editorWrapperHeight > 50 ? '!px-3' : '!px-3'
        )}
      >
        <div className="placeholder:!text-base placeholder:!font-medium placeholder:!text-[var(--text-disabled)] !w-full">
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <CKEditor
                editor={BalloonEditor}
                data={field.value}
                disabled={disabled}
                onReady={onReady}
                onFocus={() => {
                  if (editorWrapperRef.current) {
                    setEditorFocus(true);
                  }
                }}
                onBlur={() => {
                  if (editorWrapperRef.current) {
                    setEditorFocus(false);
                  }
                }}
                config={editorConfig as any}
                onChange={(event, editor) => {
                  field.onChange(editor.getData());
                  handleEditorChange(editor);
                  setTimeout(() => {
                    if (editorWrapperRef.current) {
                      setEditorWrapperHeight(
                        editorWrapperRef.current.offsetHeight
                      );
                    }
                  }, 0);
                }}
              />
            )}
          />
        </div>
      </div>

      <div className="pl-3 pr-2 pb-2 block w-full">
        <div className="flex-1 flex items-center justify-between">
          <div className="flex items-center w-max">
            <IconButton
              type="ghost"
              size="small"
              rounded="small"
              icon={<IconMention />}
              onClick={onMentionClick}
            />
            {/* @ts-ignore */}
            <CustomEmojiPicker onChange={onEmojiClick} />
            <IconButton
              type="ghost"
              size="small"
              rounded="small"
              icon={<Attachment />}
              disabled={true}
            />
          </div>
          <div className="flex items-center justify-end space-x-3">
            {isEditing && (
              <Button
                onClick={cancelEditing}
                type="button"
                size="small"
                className={'w-fit'}
                value={'Cancel'}
                btnType={'neutral'}
              />
            )}

            {isEditing && (
              <Button
                type="button"
                size="small"
                className={'w-fit'}
                value={btnLabel}
                loading={loadingBtn}
                disabled={disabledBtn}
                onClick={onClick}
                btnType={'primary'}
              />
            )}

            {!isEditing && (
              <Button
                size="medium"
                btnType="primary"
                value={btnLabel}
                loading={loadingBtn}
                disabled={disabledBtn}
                onClick={onClick}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentInputField;
