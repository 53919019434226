import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import 'src/components/sideNav/sidenav.css';
import Badge from '../ui/Badge';

interface SideNavMenuProps {
  type: 'home' | 'settings';
  link?: string;
  sideAvatar?: string;
  showActiveState?: boolean;
  sideAvatarClassName?: string;
  avatarColor?: React.CSSProperties;
  label: string;
  icon?: React.ReactNode;
  showBadge?: boolean;
  badge?: string | number;
  customClassName?: string;
  setToggleNavChiild?: () => void;
  indicator?: React.ReactNode;
  renderActionButton?: () => React.ReactNode;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const SideNavMenuBtn: React.FC<SideNavMenuProps> = (props) => {
  const {
    link,
    type,
    sideAvatar,
    sideAvatarClassName,
    avatarColor,
    customClassName,
    showActiveState = true,
    label,
    icon,
    badge,
    showBadge,
    setToggleNavChiild,
    indicator,
    renderActionButton,
  } = props;

  const location = useLocation();
  const { pathname } = location;

  return (
    <div
      className={`flex items-center justify-between w-full ${customClassName}`}
      {...props}
    >
      <div
        className={clsx(`side__menu__btn justify-between`, {
          'gap-2': type === 'home',
          active: showActiveState && pathname.includes(link || ''),
          'exact-active': showActiveState && pathname.includes(link || ''),
          capitalize: type !== 'home',
        })}
        onClick={setToggleNavChiild}
      >
        <div className="icon_label truncate">
          {icon}
          {sideAvatar && sideAvatar.length > 0 ? (
            <div>
              <div
                className={clsx(
                  'flex items-center justify-center aspect-square h-5 w-5 rounded uppercase text-body-micro bold',
                  sideAvatarClassName
                )}
                style={avatarColor}
              >
                {sideAvatar[0]}
              </div>
            </div>
          ) : null}
          <div className="flex items-center truncate gap-2">
            <span className="label text-body-small medium truncate">
              {label}
            </span>
          </div>
        </div>

        <div>
          {showBadge && (
            <span className="notification">
              <Badge value={badge} />
            </span>
          )}

          {indicator}
          {renderActionButton && renderActionButton()}
        </div>
      </div>
    </div>
  );
};

export default SideNavMenuBtn;
